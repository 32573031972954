nav.nav {
  width: 100%;
  background-color: var(--white-primary);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin-bottom: 30px; */
}

nav.nav .nav-inner-container {
  align-items: flex-start;
  position: relative;
  width: 100%;
  margin-top: 30px;
  padding-left: var(--mobile-outside-padding);
  padding-right: var(--mobile-outside-padding);
}


nav.nav .nav-links-container {
  display: none;
}

nav.nav .nav-links {
  display: none;
  align-items: center;
}

nav.nav .nav-links-container .nav-link {
  color: var(--gray-primary);
}

nav.nav .nav-links-container .nav-link.active,
nav.nav .nav-links-container .nav-link:hover {
  color: var(--active);
}

nav.nav .stacked-nav-opener,
.header .stacked-nav-opener {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin-left: 10px;
  cursor: pointer;
  /* opacity: .8; */
}

nav.nav .stacked-nav-opener:hover {
  opacity: 1;
}

nav.nav .stacked-nav-opener .icon-stacked-nav,
.header .stacked-nav-opener .icon-stacked-nav,
.progress-header-container .icon-stacked-nav {
  /* background-image: url('../images/icons/icon-stacked-nav-light.png'); */
  background-image: url('../images/icons/icons-nav/more-light.png');
  height: 30px;
}



@media screen and (max-width: 700px) {

  nav.nav .stacked-nav-opener {
    display: none;
  }

}

@media screen and (min-width: 801px) {

  nav.nav .nav-links-container {
    display: flex;
    align-items: center;
    margin-top: 17px;
    /* margin-left: 30px; */
  }

}

@media screen and (max-width: 800px) {

  nav.nav {
    /* background-image: url('../images/purple-gradient-background.jpg'); */
    background-image: url('../images/purple-background-grain-darker.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding-bottom: 30px;
  }

  nav.nav .header-welcome-text,
  nav.nav .header-label {
    color: var(--white-tertiary);
    /* opacity: .9; */
  }

  nav.nav h2,
  nav.nav .header-detail {
    color: var(--white-primary);
  }

  nav.nav .avatar-image-clean {
    border: 1px solid var(--white-primary);
  }

  .header-avatar-upload-wrapper {

  }

  .header-avatar-upload-wrapper,
  .header-avatar-upload-container {
    background-color: transparent;
  }

  .header-avatar-upload-icon {
    border-color: var(--white-primary);
    background-color: rgb(255,255,255, .15);
  }

  .header-avatar-upload-icon svg {
    color: var(--white-primary) !important  ;
  }

  .header-avatar-upload-container p {
    color: var(--white-tertiary);
  }

}





/* @media screen and (min-width: 400px) {

  nav.nav .nav-inner-container {
    padding-left: var(--tablet-outside-padding);
    padding-right: var(--tablet-outside-padding);
  }

} */

/* Responsive Layout - take affect at tablet and above */
@media screen and (min-width: 700px) {

  nav.nav {
  /* nav.nav .nav-inner-container { */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: fixed;
    z-index: 3000;
    width: 100%;
    /* height: 130px;
    min-height: 130px; */
    height: var(--nav-top-height);
    min-height: var(--nav-top-height);
    padding: initial;
    margin-bottom: initial;
    /* background-image: none; */
  }

  nav.nav .nav-inner-container {
    padding-left: var(--desktop-outside-padding);
    padding-right: var(--desktop-outside-padding);
  }

  nav.nav .nav-links {
    display: flex;
    align-items: center;
  }

  nav.nav .nav-links-container .nav-link {
    position: relative;
    padding: 5px;
    margin-right: 4px;
    font-size: .9rem;
  }

  nav.nav .stacked-nav-opener .icon-stacked-nav {
    width: 18px;
    height: 18px;
    background-image: url('../images/icons/icon-stacked-nav.png');
  }

  nav.nav .icon-notifications-light {
    background-image: url('../images/icons/icon-notifications.png');
  }

  nav.nav .icon-notifications-light.active {
    background-image: url('../images/icons/icon-notifications-active.png');
  }

  nav.nav .nav-links-container .nav-link-icon-container {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 12px;
    height: 12px;
  }

}

@media screen and (max-width: 750px) {
  nav.nav .nav-links-container .nav-link {
    font-size: .9rem;
  }
}



@media screen and (min-width: 1000px) {

  nav.nav .nav-links-container .nav-link {
    padding: 10px;
    margin-right: 10px;
    font-size: .95rem;
  }

  nav.nav .nav-links-container .nav-link:last-of-type {
    margin-right: initial;
  }

  nav.nav .nav-links-container .nav-link-icon-container {
    top: 5px;
    right: 1px;
  }

}

@media screen and (min-width: 1100px) {

  nav.nav .nav-links-container .nav-link {
    padding: 10px;
    margin-right: 10px;
    font-size: .95rem;
  }

}




/* ------------------------------------------------------------ */
/* Profile Setup */
/* ------------------------------------------------------------ */

.window-modal.profile-setup-modal {
  max-width: 500px;
  max-height: calc(100% - 60px);
  padding: 0px;
  overflow-y: scroll;
}

.window-modal.profile-setup-modal .window-modal-header {
  align-items: flex-start;
  padding: 30px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-image: url('../images/purple-background-grain-darker.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.window-modal.profile-setup-modal .window-modal-header p,
.window-modal.profile-setup-modal .window-modal-header h2 {
  color: var(--white-primary);
  font-family: 'Outfit', sans-serif;
  text-align: center;
}

.window-modal.profile-setup-modal .window-modal-header p {
  font-size: 1.35rem;
  font-weight: 300;
}

.window-modal.profile-setup-modal .window-modal-body {
  padding: 30px;
}

.window-modal.profile-setup-modal .window-modal-body h3 {
  margin-bottom: 30px;
  font-family: 'Outfit', sans-serif;
  font-weight: 400;
  font-size: 1.3rem;
  text-align: center;
}

.profile-setup-wrapper {
  justify-content: flex-end;
  margin-bottom: 15px;
}

.profile-setup-container {
  padding: 8px 12px;
  border-radius: 100px;
  color: var(--white-primary);

  font-family: 'Outfit', sans-serif;
  font-weight: 500;
  font-size: .9rem;

  background: rgb(214,56,174);
  background: linear-gradient(148deg, rgba(214,56,174,1) 0%, rgba(173,13,161,1) 100%);
}

.profile-setup-container small {
  margin-left: 15px;
  font-weight: 300;
}

.setup-profile-block {
  position: relative;
  margin-top: 15px;
}

.setup-profile-block-inner {
  padding: 15px;
  text-align: center;
  border-radius: 5px;
  border: 1px solid var(--white-tertiary);
  background-color: var(--gray-light);
}

.setup-profile-block-inner p {
  padding: 0 45px;
}

.setup-profile-block-inner.complete {
  opacity: .5;
}

.profile-setup-complete-mark {
  position: absolute;
  z-index: 100;
  top: 15px;
  right: 10px;
}

.setup-profile-block-inner select {
  text-align: center;
}

.window-modal.profile-setup-modal .profile-input-wrapper {
  margin-bottom: 0px;
}

.profile-input-wrapper .profile-input.setup-profile-upload-container {
  justify-content: center;
  overflow: hidden;
  width: 100%;
}

button.setup-profile-upload-button {
  margin: 15px 0 15px 0;
}


.profile-setup-avatar-picker-button {
  margin: 15px 0;
  color: var(--gray-tertiary);
  font-size: .9rem;
  font-style: italic;
  text-decoration: underline;
}

.default-avatar-container {
  flex-wrap: wrap;
}

.default-avatar-container button {
  width: 20%;
  min-width: 50px;
  max-width: 70px;
  padding: 3px;
}

.default-avatar-container img {
  border-radius: 100px;
  opacity: .5;
}

.default-avatar-container img.selected {
  box-shadow: 0 3px 5px 0px rgb(0,0,0, .5);
  opacity: 1;
}


@media screen and (max-width: 549px) {

  .window-modal.profile-setup-modal {
    width: 100%;
    max-height: 100vh;
  }

}

@media screen and (max-width: 375px) {

  .profile-setup-container {
    padding: 8px;
    font-size: .8rem;
  }

  .profile-setup-container small {
    margin-left: 5px;
  }

}