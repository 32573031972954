
/* ------------------------------------------------------------ */
/* Layout */
/* ------------------------------------------------------------ */

.lesson-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: var(--gray-background);
}

.lesson-left-column {
  display: none;
}

.lesson-right-column {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  min-height: 450px;
  background-color: var(--white-primary);
}

.body-content {
  flex-grow: 1;
  overflow-y: scroll;
  height: 100vh;
}

/* .body-content.community {

} */

.body-content.related-lessons {
  padding-bottom: 30px;
}

.body-content.related-lessons .lesson-card-details-container {
  background-color: var(--gray-light);
}

.lesson-right-column .lesson-card-outer-wrapper {
  /* padding: 15px 30px 15px 30px; */
  padding: 15px 30px 15px 15px;
}

.lesson-right-column .lesson-card {
  margin-bottom: 0px;
}


/* Responsive Layout - take affect at tablet and above */
@media screen and (min-width: 700px) {

  /* .lesson-body {
    display: flex;
  } */

  /* .lesson-left-column {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 50%;
    padding: 30px;
    border-right: 1px solid var(--gray-tertiary);
    background-color: var(--gray-background);
  } */

  .lesson-right-column {
    border-left: 1px solid var(--white-tertiary);
    border-right: 1px solid var(--white-tertiary);
  }

  .lesson-right-column {
    width: 75%;
    max-width: 600px;;
  }

  .related-tab {
    display: none;
  }

  .related-lesson-wrapper {
    overflow-y: scroll;
    padding: 0 10px;
  }

  .related-lessons-title {
    text-align: center;
    margin: 15px 0;
    font-weight: 600;
  }

  .related-lesson-wrapper .lesson-card {
    opacity: .6;
  }

  .related-lesson-wrapper .lesson-card:hover {
    opacity: 1;
  }

  .lesson-right-column .lesson-card-outer-wrapper {
    display: none;
  }

}

/* Make the lesson header fixed on mobile devices */
@media screen and (max-width: 700px) {

  /* .lesson-right-column {
    display: block;
  } */

  /* .lesson-header-wrapper {
    position: fixed;
    z-index: 3000;
    width: 100%;
    top: 0px;
  } */

  .body-content {
    /* margin-top: 130px; */
  }

  /* .body-content.community,
  .body-content.related-lessons {
    margin-top: 110px;
  } */

}

@media screen and (min-width: 1100px) {

  .lesson-left-column {
    padding-left: 60px;
    padding-right: 60px;
  }

}

@media screen and (min-width: 1300px) {

  .lesson-left-column {
    padding-left: 120px;
    padding-right: 120px;
  }

}



/* ------------------------------------------------------------ */
/* Header Lesson */
/* ------------------------------------------------------------ */


.lesson-header-content-container {
  padding-bottom: 15px;
  border-bottom: 1px solid var(--white-tertiary);
}

.header-lesson-wrapper {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.header-lesson-container {
  background: linear-gradient(180deg, rgba(0,0,0,.35) 0%, rgba(0,0,0,0) 100%);
}

.header-lesson-actions {
  justify-content: flex-start;
  margin-bottom: 45px;
  padding: 30px;
}

.header-lesson-actions a {
  margin-right: 5px;
}

.header-lesson-title-container {
  text-align: center;
  padding: 30px;
  padding-bottom: 15px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  background-color: var(--white-primary);
}

.header-lesson-title-container h1 {
  font-size: 1.25rem;
  font-weight: 500;
}

.lesson-header-content-container .screen-toggler-wrapper {
  /* Need this to cover a gap that shows when the screen is sized down
  this happens on some phone screens */
  margin-top: -2px;
  background-color: var(--white-primary);
}

.lesson-progress-steps-wrapper {
  padding: 15px 30px 0;
}



@media screen and (max-width: 460px) {

  .screen-toggler-container .faux-button {
    padding: 10px 20px;
    font-size: .9rem;
  }

}

@media screen and (max-height: 670px) {

  .header-lesson-actions {
    margin-bottom: 15px;
  }

}





/* ------------------------------------------------------------ */
/* Chat Styles */
/* ------------------------------------------------------------ */

.question-wrapper .card-content {
  border-radius: 20px;
  /* margin: 10px 0; */
}

.response-wrapper .card-content,
.response-wrapper.active.in-review .card-content {
  border-radius: 20px;
  background-color: var(--active);
  color: var(--white-primary);
  width: 100%;
  padding: 10px 20px;
}

.question-wrapper.introduction .card-content {
  background-color: var(--information);
  min-width: 200px;
}

.question-wrapper.question .card-content {
  background-color: var(--gray-light);
}

.question-wrapper.almost-complete .card-content,
.question-wrapper.lesson-complete .card-content {
  background-color: var(--handy-dark);
  color: #fff;
}

.question-wrapper.lesson-complete .card-content {
  font-weight: 600;
}

.question-wrapper.introduction .card-content .icon-audio,
.question-wrapper.question .card-content .icon-audio {
  background-image: url('../images/icons/icon-audio-right-dark.png');
}

/* .question-wrapper.lesson-complete.shake {
  animation: shake .9s;
} */

@keyframes shake {
  /* 0% { transform: translate(1px, 1px) rotate(1deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-1px, 0px) rotate(1deg); }
  30% { transform: translate(1px, 1px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-1px, 1px) rotate(0deg); }
  70% { transform: translate(2px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 1px) rotate(0deg); }
  100% { transform: translate(1px, -1px) rotate(-1deg); } */
  0%, 100% {transform: translateX(0) rotate(-.3deg);}
   10%, 30%, 50%, 70%, 90% {transform: translateX(-4px) rotate(0deg);}
   20%, 40%, 60%, 80% {transform: translateX(7px) rotate(.3deg);}
}




/* ------------------------------------------------------------ */
/* Misc Styles */
/* ------------------------------------------------------------ */

.no-content-wording {
  margin: 30px;
}

p.blank-state {
  max-width: 400px;
}

p.blank-state .icon {
  display: inline-block;
  margin-bottom: -5px;
}

.icon-gem {
  margin-right: 15px;
  margin-left: 15px;
}

@media screen and (min-width: 475px) {

  .body-content.related-lessons {
    padding: 15px 30px;
  }

}



.loading-div {
  background-color: var(--gray-light);
  padding: 8px 10px;
  display: inline-block;
  min-width: 75px;
  border-radius: 20px;
  margin-left: 15px;
}

.microphone-access-message-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: rgba(0, 0, 0, 0.5);
}

.microphone-access-message.help-bubble {
  top: 35%;
  text-align: center;
  padding: 30px;
  font-size: .9rem;
}

@media screen and (min-width: 700px) {

  .microphone-access-message.help-bubble {
    width: 60%;
  }

}





/* ------------------------------------------------------------ */
/* Next Question Button */
/* ------------------------------------------------------------ */

.next-question-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 60px;
  height: 46px;
}

.next-question-wrapper button {
  padding: 10px 20px;
  background-color: var(--gray-extra-light);
  font-weight: 500;
  border: 1px solid var(--gray-tertiary);
  border-radius: 5px;
  cursor: pointer;
}

.next-question-wrapper button:hover {
  background-color: var(--gray-light);
}




/* ------------------------------------------------------------ */
/* Tooltip */
/* ------------------------------------------------------------ */


#tooltip-icon-wrapper {
  font-size: .9rem;
}

#tooltip-icon-wrapper .icon-info {
  /* display: inline-block; */
  width: 16px;
  height: 16px;
  opacity: .75;
}

#tooltip-icon-wrapper .tooltip {
  position: absolute;
  z-index: 1000;
  /* height: 65px; */
  padding: 5px 10px;
  border-radius: 5px;
  max-width: 300px;
  margin-left: -150px;

  background-color:var(--information);
}


/* #tooltip-icon-wrapper .tooltip .arrow-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 12px solid var(--information);
  position: absolute;
  left: 15px;
  bottom: -10px;
} */



/* ------------------------------------------------------------ */
/* No Access Modal */
/* ------------------------------------------------------------ */

.lesson-access-modal-wrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 30001;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
}

.lesson-access-modal {
  width: 90%;
  max-width: 400px;
  height: 350px;
  padding: 30px;
  background-color: var(--white-primary);
  border-radius: 5px;
  box-shadow: 0 0px 8px 0px rgb(0,0,0, .5);
}

.lesson-access-modal .lesson-access-modal-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 30px;
}

.lesson-access-modal .lesson-access-modal-header .icon {
  width: 16px;
  height: 16px;
  margin-left: 30px;
}

.lesson-no-access-message {
  margin: 30px 15px;
  padding: 30px;
  border-radius: 5px;
}

.lesson-no-access-message p.special {
  font-size: 1.125rem;
  font-weight: 500;
  color: var(--active);
}

.lesson-no-access-message a {
  padding: 15px;
  text-decoration: underline;
  color: var(--gray-primary);
}

.lesson-no-access-message a:hover {
  color: var(--active);
}




/* ------------------------------------------------------------ */
/* Poll */
/* ------------------------------------------------------------ */

.lesson-card.poll {
  background-color: var(--white-primary);
}

.lesson-card.poll h3 strong {
  display: block;
  margin-bottom: 10px;
}

.lesson-card.poll h3 {
  font-size: 1rem;
}

.lesson-card.poll .poll-description {
  font-style: italic;
  /* font-size: .9rem; */
  margin-top: 5px;
  margin-bottom: 15px;
}

.poll-item-wrapper {
  display: flex;
  align-items: center;
  margin-top: 10px;
  border: 1px solid var(--gray-tertiary);
  border-radius: 5px;
  cursor: pointer;

}

.poll-item-wrapper:hover {
  box-shadow: 0 1px 6px -1px rgb(0 0 0 / 20%);
}

.poll-item-wrapper.selected {
  border-color: var(--active);
  color: var(--active);
  background-color: var(--gray-extra-light);
  box-shadow: 0 1px 6px -1px rgb(0 0 0 / 35%);
}

.poll-item-wrapper span {
  padding: 10px;
  height: 100%;
  pointer-events: none;
}

.poll-item-wrapper span.poll-label {
  padding: 10px 15px;
}

.poll-item-wrapper span.poll-item {
  border-left: 1px solid var(--gray-tertiary);
}

.poll-item-wrapper span.poll-item i {
  display: block;
  font-size: .9rem;
}

.lesson-card.poll button.button {
  margin-top: 15px;
  border-color: var(--gray-tertiary);
}

.lesson-card.poll .poll-submitted {
  font-size: 1.125rem;
}




/* ------------------------------------------------------------ */
/* Course Complete Modal */
/* ------------------------------------------------------------ */

.window-modal.course-complete {
  text-align: center;
}

.window-modal.course-complete .window-modal-header {
  flex-direction: column;
}

.window-modal.course-complete .modal-title {
  font-size: 1.5rem;
  font-weight: 600;
}

.window-modal.course-complete .window-modal-subtitle {
  font-size: 1.25rem;
}

.window-modal.course-complete .window-modal-body {
  font-size: .9rem;
}

.window-modal.course-complete .window-modal-footer button {
  font-weight: 500;
  color: var(--white-primary);
  padding: 15px 40px;
  background: rgb(32,89,255);
  background: linear-gradient(45deg, rgba(32,89,255,1) 0%, rgba(166,52,255,1) 100%);
  border-radius: 10px;
  border: none;
}





/* ------------------------------------------------------------ */
/* IELTS Scorecard */
/* ------------------------------------------------------------ */

.lesson-scorecard-wrapper,
.ielts-course-complete .window-modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px;
}

.lesson-scorecard-details,
.course-scorecard-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  background-color: var(--gray-light);
  border-radius: 5px;
}

.lesson-scorecard-details .scorecard-title {
  font-size: 1.25rem;
}

.lesson-scorecard-overall-score,
.course-scorecard-overall-score {
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 500;
  margin: 15px 0;
}

.lesson-scorecard-overall-score p,
.course-scorecard-overall-score p {
  margin-right: 10px;
}

/* .lesson-scorecard-scores {

} */

.lesson-scorecard-score,
.course-scorecard-score {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.lesson-scorecard-score .score-title,
.course-scorecard-score .score-title {
  line-height: 1.2rem;
  margin-right: 15px;
}

.lesson-scorecard-score .score,
.course-scorecard-score .score {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.lesson-scorecard-score .score p,
.course-scorecard-score .score p {
  margin-right: 5px;
}



/* ------------------------------------------------------------ */
/* IELTS Course Complete Modal */
/* ------------------------------------------------------------ */

.window-modal-wrapper.ielts-course-complete {

}

.window-modal-wrapper.ielts-course-complete .window-modal {
  padding: 0px;
}

.window-modal-wrapper.ielts-course-complete .window-modal .window-modal-header {
  padding: 30px;
  padding-bottom: 15px;
}

.window-modal-wrapper.ielts-course-complete .window-modal-body {
  margin: initial;
}

.window-modal-wrapper.ielts-course-complete .window-modal-body p {
  margin: initial;
}

.course-scorecard-overall-score {
  margin: initial;
}

.window–modal-wrapper.ielts-course-complete .window-modal-body .description {
  margin: 5px 0 15px 0;
  font-size: .9rem;
  text-align: center;
}

.course-scorecard-details {
  width: 100%;
}

.course-scorecard-details .scorecard-title {
  font-weight: 500;
  margin-bottom: 15px;
}

.window–modal-wrapper.ielts-course-complete .window-modal-body .course-scorecard-score .score p {
  margin-right: 5px;
}




/* ------------------------------------------------------------ */
/* Completing lesson loading */
/* ------------------------------------------------------------ */

.complete-lesson-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.complete-lesson-loading div {
  display: flex;
  align-items: center;
  width: 250px;
  padding: 10px 15px;
  background-color: var(--handy-dark);
  border-radius: 30px;
  font-size: 1.125rem;
  color: var(--white-primary);
}

.complete-lesson-loading div span {
  margin-left: 5px;
}








/* ------------------------------------------------------------ */
/* New Lesson Format */
/* ------------------------------------------------------------ */

/* section.lesson-header {

} */

section.lesson-content {
  flex-grow: 1;
  overflow-y: scroll;
  padding-bottom: 60px;
  background-color: var(--white-primary);
}

section.lesson-footer-fixed {
  padding: 15px;
}






/* ------------------------------------------------------------ */
/* Lesson Complete Modal */
/* ------------------------------------------------------------ */

.window-modal.lesson-complete-modal {
  padding: 0px;
  max-height: 90vh;
  overflow-y: scroll;
}

.window-modal.lesson-complete-modal .modal-header,
.window-modal.lesson-complete-modal .modal-body,
.window-modal.lesson-complete-modal .modal-footer {
  padding: 30px;
}

.window-modal.lesson-complete-modal .modal-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 300;
  padding-bottom: 15px;
}

.window-modal.lesson-complete-modal .modal-header .border-bottom {
  display: flex;
  flex-direction: row;
  height: 4px;
  width: 100%;
  margin-top: 15px;
  gap: 4px;
}

.window-modal.lesson-complete-modal .modal-header .border-bottom span {
  height: 100%;
  width: 1px;
  flex-grow: 1;
  border-radius: 10px;
  background-color: var(--active);
}



.window-modal.lesson-complete-modal .modal-body-score-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px 0;
}

.window-modal.lesson-complete-modal .modal-body-score-container h1 {
  font-size: 3rem;
  line-height: 2.75rem;
  font-weight: 500;
}

.window-modal.lesson-complete-modal .modal-body-score-container p {
  font-family: 'Outfit', sans-serif;
  font-size: 1.5rem;
  font-weight: 400;
}

.window-modal.lesson-complete-modal .modal-body-points-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px 0 30px 0;
  font-family: 'Outfit', sans-serif;
}

.window-modal.lesson-complete-modal .modal-body-points-container .you-earned {
  font-weight: 300;
  font-style: italic;
}

.window-modal.lesson-complete-modal .modal-body-points-container .earned-banner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 315px;
  height: 35px;
  margin-top: 5px;
  padding: 5px;

  background-image: url('../images/points-bar-background.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}


.window-modal.lesson-complete-modal .share-responses-container {
  justify-content: center;
  margin-top: 15px;
  padding: 10px;
  font-size: .9rem;
  border-top: 1px solid var(--white-tertiary);
  background-color: var(--gray-background);
}

.window-modal.lesson-complete-modal .share-responses-container p {
  margin-left: 5px;
}


.window-modal.lesson-complete-modal .course-container .flex-column,
.window-modal.lesson-complete-modal .course-container .score-row {
  width: 100%;
}

.window-modal.lesson-complete-modal .course-container {
  padding: 30px;
  border-top: 1px solid var(--white-tertiary);
  background-color: var(--gray-light);
}

.window-modal.lesson-complete-modal .course-container h3 {
  font-size: 1.35rem;
  font-family: 'Outfit', sans-serif;
}

.window-modal.lesson-complete-modal .course-container h4 {
  margin-top: 15px;
  color: var(--active);
  font-family: 'Outfit', sans-serif;
}



.window-modal.lesson-complete-modal .modal-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid var(--white-tertiary);
}

.window-modal.lesson-complete-modal .modal-footer .footer-back-link {
  font-size: .9rem;
  color: var(--gray-tertiary);
  margin-top: 5px;
}

.window-modal.lesson-complete-modal .modal-footer .footer-back-link:hover {
  text-decoration: underline;
}


@media screen and (max-width: 385px) {

  .window-modal.lesson-complete-modal .modal-body-points-container {
    padding: 15px 0;
  }

}

@media screen and (max-width: 400px) {

  .window-modal.lesson-complete-modal .modal-body-points-container .earned-banner {
    max-width: 275px;
  }

}



/* ------------------------------------------------------------ */
/* Next Lesson */
/* ------------------------------------------------------------ */

.next-lesson-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: var(--gray-primary);
}

.next-lesson-container a {
  color: var(--gray-primary);
}

.next-lesson-container .next-instruction {
  font-family: 'Outfit', sans-serif;
  font-weight: 300;
  font-size: 1.125rem;
}

.next-lesson-link-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 300px;
  cursor: pointer;
}

.next-lesson-details-container {
  padding: 15px;
  margin: 10px 0;
  border-radius: 5px;
  width: 100%;
  border: 1px solid var(--gray-light);
  background-color: var(--gray-light);
}

.next-lesson-container .next-title {
  font-weight: 500;
}

.next-lesson-container .next-type {
  font-size: .9rem;
  margin-top: 3px;
  color: var(--gray-tertiary);
  text-transform: capitalize;
}

.next-lesson-container .next-duration {
  font-size: .9rem;
  color: var(--gray-tertiary);
}

.next-lesson-link-container svg {
  margin-left: -1.3rem;
  opacity: 1;
}



/* ------------------------------------------------------------ */
/* Translation */
/* ------------------------------------------------------------ */

.translate-toggle-container {
  padding: 10px;
  background-color: #FBFBFB;
}

.translate-toggle-container .profile-input-wrapper {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  margin-bottom: initial;
}

.translate-toggle-container .profile-input {
  margin-right: 10px;
}

.translate-container {
  margin-top: -1px;
  margin-left: 15px;
  margin-right: 15px;
  padding: 10px;
  font-size: .9rem;
  color: var(--gray-tertiary);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  /* background-color: #F4F9FC; */
  border: 1px solid var(--white-tertiary);
  border-top: none;
}

.translate-container strong {
  color: var(--gray-tertiary);
}

.translation-help-bubble {
  margin: 5px 10px 10px 10px;
  color: var(--gray-tertiary);
  font-size: .9rem;
}


/* AI Talk overrides */
.lesson-body.aitalk .translate-container {
  margin-left: 30px;
}





/* ------------------------------------------------------------ */
/* Messages */
/* ------------------------------------------------------------ */

.message-container {
  width: 100%;
}

.message-content {
  max-width: 90%;
  padding: 15px 20px;
  border-radius: 30px;
}

/* Speaker */
/* .message-container.message-speaker {

} */

.message-container.message-speaker .message-content {
  text-align: left;
  background-color: var(--information);
}

.message-container.message-speaker .message-content-audio {
  margin-left: 15px;
}


/* Learner */
.message-container.message-learner {
  justify-content: flex-end;
}

.message-container.message-learner .message-content {
  text-align: right;
  background-color: var(--gray-light);
}

.message-container.message-learner .message-content-audio {
  margin-right: 15px;
}




/* ------------------------------------------------------------ */
/* Audio Speed Switcher */
/* ------------------------------------------------------------ */

.audio-speed-switcher-continer {
  margin-bottom: 30px;
}

.audio-speed-switcher-continer p {
  margin-bottom: 3px;
  text-align: center;
  font-size: .9rem;
  color: var(--gray-tertiary);
}

.audio-speed-switcher {
  border: 1px solid var(--white-tertiary);
  border-radius: 40px;
  background-color: var(--gray-background);
}

.audio-speed-switcher-continer button {
  padding: 6px 12px;
  border: initial;
  font-family: inherit;
  font-size: .9rem;
  background-color: transparent;
}

.audio-speed-switcher-continer button.active {
  border-radius: 40px;
  background-color: var(--white-tertiary);
}

.audio-speed-switcher-continer button.inactive {
  color: var(--gray-inactive);
  cursor: pointer;
}

.audio-speed-switcher-continer button.inactive:hover {
  color: var(--gray-primary);
}