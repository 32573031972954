

.main-body-content.profile {
  max-width: 700px;
}



/* ------------------------------------------------------------ */
/* Profile Subnav */
/* ------------------------------------------------------------ */

.profile-subnav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.profile-subnav a {
  font-size: 1rem;
  font-weight: 500;
  color: var(--gray-primary);
  padding: 5px 15px;
  border-radius: 5px;
}

.profile-subnav a.active {
  background-color: var(--gray-primary);
  color: var(--white-primary);
}


@media screen and (min-width: 700px) {

  .profile-subnav {
    justify-content: flex-start;
    margin-top: 30px;
  }

}




/* ------------------------------------------------------------ */
/* Form fields */
/* ------------------------------------------------------------ */

.profile-input-wrapper {
  margin-bottom: 30px;
}

.profile-input-wrapper label {
  display: block;
  margin-bottom: 3px;
  color: var(--gray-tertiary);
}

.profile-input-wrapper .profile-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.profile-input-wrapper input,
.profile-input-wrapper select {
  padding: 6px;
  font-weight: 500;
  width: 240px;
  border: 1px solid var(--gray-tertiary);
  border-radius: 5px;
  color: var(--gray-primary);
}

.profile-input-wrapper input:disabled,
.profile-input-wrapper select:disabled {
  background-color: transparent;
  border-color: transparent;
}

.profile-input-wrapper .profile-input-buttons button {
  font-size: .8rem;
  padding: 10px 6px;
  color: var(--gray-tertiary);
  cursor: pointer;
}

.profile-input-wrapper .profile-input-buttons .icon-edit {
  opacity: .75;
  width: 14px;
  height: 14px;
}

.profile-input-wrapper .profile-input-buttons button:last-of-type {
  margin-left: 5px;
}

.profile-input-wrapper .avatar-wrapper {
  width: 50px;
  height: 50px;
}

.profile-input-wrapper .avatar-edit-wrapper {
  display: flex;
  justify-content: center;
  margin: 15px;
}




/* ------------------------------------------------------------ */
/* Subscription Information */
/* ------------------------------------------------------------ */

.subscription-description ul {
  font-size: .9rem;
  margin: 10px 0 30px 30px;
  list-style-type: disc;
}

.subscription-description p.small {
  font-size: .9rem;
  margin-bottom: 10px;
}




/* ------------------------------------------------------------ */
/* Extras */
/* ------------------------------------------------------------ */

.profile-extras {
  margin-top: 45px;
  padding-top: 30px;
  border-top: 1px solid var(--white-tertiary);
}

.profile-extras a {
  display: block;
  color: var(--gray-tertiary);
  padding: 10px 0;
  margin-bottom: 15px;
}


.autoplay-guide {
  font-size: .9rem;
}

.autoplay-guide p {
  margin-top: 10px;
}

.autoplay-guide ul {
  list-style:circle;
  margin-top: 10px;
  margin-left: 30px;
}

.autoplay-guide ul li {
  padding: 2px 0;
}
