
.main-body-content.notifications {
  max-width: 680px;
}

.main-body-content.notifications .card {
  padding: 15px;
  margin-bottom: 15px;
}


.main-body-content.notifications .notification_header {
  display: flex;
  justify-content: space-between;
  font-size: .9rem;
  margin-bottom: 10px;

}

.main-body-content.notifications .notification-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-body-content.notifications .notification_text {
  font-style: italic;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 230px;
  max-width: 230px;
  padding-left: 10px;
}

.main-body-content.notifications .notification-details.feedback_request {
  justify-content: flex-start;
}

.main-body-content.notifications .notification-details.feedback_request .notification_text {
  width: 30px;
}

.main-body-content.notifications .notification-details.feedback_request .button {
  border: initial;
  text-decoration: underline;
  font-size: initial;
}