.window-modal.score-details-modal {
  padding: 30px 20px;
}


.score-details-modal p.modal-title {
  color: var(--gray-primary);
}

.score-details-container h1 {
  font-size: 3rem;
  line-height: 3.4rem;
}

.score-details-container .score-breakdown-container {
  margin-top: 15px;
}

.score-details-container .score-breakdown-container .flex-row {
  justify-content: center;
  margin: 10px 0;
}

.score-details-container .score-breakdown-container .flex-column {
  align-items: flex-start;
  margin-left: 10px;
}

.score-details-container .score-breakdown-container h4 {
  font-family: 'Outfit', sans-serif;
}

.score-details-container .score-breakdown-container .score-donut-wrapper, .score-donut-wrapper {
  width: 40px;
  height: 40px;
}

.score-details-container .score-breakdown-container .pie {
  --b: 7px;
  --w: 40px;
}

.score-details-container .score-breakdown-container .score-donut-wrapper .donut-hole {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border-width: 7px;
}

.score-details-container p.subline {
  font-size: .95rem;
  line-height: 1.2rem;
  text-align: center;
  color: var(--gray-tertiary);
  margin-bottom: 0px;
}

.score-details-container .score-details-footer {
  margin-top: 30px;
}

.score-details-container .score-details-footer p,
.score-details-container .score-details-footer strong {
  color: var(--gray-tertiary);
  margin-bottom: 0px;
}


.score-details-container .score-details-blank-state-container {
  margin-top: 30px;
  color: var(--gray-primary);
  text-align: center;
}

.score-details-container .score-details-blank-state-container strong {
  color: var(--active);
}




@media screen and (max-width: 500px) {

  .window-modal.score-details-modal {
    width: 87%;
  }

}