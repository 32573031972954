
/* ------------------------------------------------------------ */
/* Base styles in the Bite show view */
/* ------------------------------------------------------------ */

.lesson-right-column.bites {
  position: relative;
}

.bite-background-image,
.bite-content {
  position: absolute;
  z-index: 1000;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}

.lesson-card.bite .bite-content,
.lesson-card.bite .bite-background-image {
  border-radius: 5px;
}


@media screen and (max-width: 700px) {

.bite-wrapper .bite-background-image {
  height: calc( 100% + var(--iphone-bottom-offset) );
}

}

.bite-content {
  position: relative;
  z-index: 1002;
}

.bite-top-fade {
  position: absolute;
  z-index: 1001;
  width: 100%;
  /* height: 400px; */
  /* max-height: 80%; */
  height: 70%;
  top: 0px;
  left: 0px;
  background: linear-gradient(180deg, rgba(0,0,0,.8) 0%, rgba(0,0,0,0) 100%);
}

.bite-bottom-fade {
  position: absolute;
  z-index: 1001;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.8) 100%);
}

.lesson-card.bite .bite-top-fade {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.lesson-card.bite .bite-bottom-fade {
  border-radius: 5px;
  border-radius: 5px;
}

.bite .card-details-wrapper {
  margin-bottom: 10px;
}


/* ------------------------------------------------------------ */
/* Title & Secondary Line */
/* ------------------------------------------------------------ */

.bite .bite-header {
  display: flex;
  align-items: top;
  justify-content: space-between;
}

.bite .bite-header .icon {
  height: 16px;
  min-height: 16px;
  width: 16px;
  min-width: 16px;
  margin-top: 5px;
  margin-left: 15px;
}

.bite .title {
  margin: 0px;
  font-size: 1.5rem;
}

.bite .title.small {
  font-size: 1.25rem;
}

.bite .title.smaller {
  font-size: 1.125rem;
}

.bite .title.secondary-line {
  margin-top: 15px;
  font-weight: 400;
}


.bite .title.speak {
  text-align: center;
}

.bite .title.secondary-line.speak {
  margin-top: 45px;
  font-size: 1.5rem;
  font-weight: initial;
}

.bite .title.secondary-line.speak span.answer-say {
  display: block;
  font-size: 1.125rem;
  margin-bottom: 15px;
  font-weight: 600;
}

.bite .title.secondary-line.speak span.answer-text {
  display: flex;
  align-items: center;
  padding: 15px;
  font-size: 1.4rem;
  line-height: 1.75rem;
  /* background-color: rgba(0, 0, 0, 0.75); */
  background-color: rgba(39, 18, 42, 0.85);
  border-radius: 5px;
}

.bite .title.secondary-line.speak span.answer-text .media-left {
  margin-left: 15px;
  margin-right: 0px;
}

.bite .title.secondary-line.speak.small span.answer-text {
  font-size: 1.3rem;
}

.bite .title.secondary-line.speak.smaller span.answer-text {
  font-size: 1.25rem;
}



/* ------------------------------------------------------------ */
/* Body */
/* ------------------------------------------------------------ */


.lesson-body.bite .body-content {
  margin-top: 0px;
}

.lesson-body.bite .bite-content {
  display: flex;
  flex-direction: column;
  padding: 30px;
  /* height: 100vh; */
}

.lesson-right-column.bites .bite-content {
  height: calc( 100vh - var(--iphone-bottom-offset) );
}

.lesson-body.bite .bite-content .title {
  color: var(--white-primary);
}

.lesson-body.bite .secondary-line {
  flex-grow: 1;
}

.bite-bottom {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}


/* ------------------------------------------------------------ */
/* Answers */
/* ------------------------------------------------------------ */

.bite-answer-wrapper {
  display: flex;
  align-items: center;
  margin: 15px 0;
}

.bite-answer-wrapper .icon {
  /* margin-left: 15px; */
  /* width: 24px; */
  /* min-width: 24px; */
  /* height: 24px; */
  /* opacity: .9; */
}

.bite-answer-wrapper .icon.icon-audio {
  margin-right: 10px;
}

.bite-answer-wrapper .icon-audio-wrapper {
  height: 24px;
}

.bite-answer-wrapper .bite-answer-content {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  color: var(--gray-primary);
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.95);
}

.bite-answer {
  display: block;
  /* padding: 8px 15px; */
  margin-right: 8px;
  text-align: left;
  border-top-left-radius: 18px;
  border-bottom-left-radius: 18px;
  cursor: pointer;
}

.bite-answer-content.selected {
  background-color: var(--active);
}

.bite-answer-content.selected button {
  color: var(--white-primary);
}


/* ------------------------------------------------------------ */
/* Action */
/* ------------------------------------------------------------ */

.bite-action-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  height: 60px;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  opacity: .85;
  background-color: var(--white-primary);
  border-radius: 10px;
}

.bite-action-wrapper button.frame-button {
  display: block;
  width: 100%;
  height: 100%;
  padding: 15px 30px;
  font-size: 1.5rem;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
}

.bite-action-wrapper.waiting {
  color: var(--gray-tertiary);
  opacity: .70;
  padding: 15px 30px;
  padding-top: 16px; /* Hack to align the text */
}

.bite-action-wrapper.check {
  cursor: pointer;
}

.bite-action-wrapper.incorrect {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: var(--error-text-alt); */
  /* color: var(--white-primary); */
  color: var(--error-text-alt);
  font-size: 1.125rem;
}

.bite-action-wrapper.correct,
.bite-action-wrapper.record-complete {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--active);
  color: var(--white-primary);
  font-size: 1.5rem;
}

.bite-action-wrapper.correct div,
.bite-action-wrapper.record-complete div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 15px 30px;
}

.bite-action-wrapper.record-waiting {
  opacity: 0;
}



/* ------------------------------------------------------------ */
/* Record */
/* ------------------------------------------------------------ */

.bite-record-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

.bite-record-wrapper .response-content {
  margin-left: initial;
  align-items: center;
  width: fit-content;
  min-width: 200px;
}

.bite-record-wrapper .response-content .media {
  align-items: center;
}

.bite-record-wrapper .record-response-button {
  background-color: var(--white-primary);
  padding: 0px;
}

.bite-record-wrapper .card-content {
  min-width: 200px;
  border-radius: 20px;
  background-color: var(--white-primary);
}

.bite-record-wrapper .card-content #record-icon {
  background-image: url('../images/icons/icon-microphone-dark.png');
}

.bite-record-wrapper .card-content .listening-spinner > div,
.bite-record-wrapper .card-content .audio-processing-circle .sk-circle:before {
  background-color: var(--gray-primary);
}

.bite-card-content .flex-row {
  flex-direction: row-reverse;
}

.bite-card-content .icon-audio {
  transform: rotate(180deg);
  margin-right: 10px;
}

/* .bite-record-wrapper .response-extras {
  color: var(--gray-primary);
} */

.bite-record-wrapper .response-extras .try-again,
.bite-record-wrapper .response-extras .response-score {
  color: var(--white-primary);
}


.bite-record-wrapper .media-content {
  overflow-x: initial;
}



/* ------------------------------------------------------------ */
/* Can't Talk */
/* ------------------------------------------------------------ */

.bite-wrapper .cant-talk-wrapper {
  display: flex;
  justify-content: center;
}

.bite-wrapper .cant-talk-wrapper button {
  width: 200px;
  padding: 7px 10px;
  font-size: .9rem;
  color: var(--white-tertiary);
  border-radius: 20px;
  background-color: var(--gray-primary);
  cursor: pointer;
}



/* ------------------------------------------------------------ */
/* Score Details */
/* ------------------------------------------------------------ */

.bite-wrapper .score-details {
  top: 300px !important;
}

.bite-wrapper .score-details .arrow-up {
  display: none;
}




/* ------------------------------------------------------------ */
/* Complete Modal */
/* ------------------------------------------------------------ */

.window-modal.bite-complete {
  text-align: center;
}

.window-modal.bite-complete .window-modal-header {
  display: block;
}

.window-modal.bite-complete .window-modal-header p.modal-title {
  font-size: 1.125rem;
}


.window-modal.bite-complete a {
  color: var(--gray-primary);
}

.window-modal.bite-complete a.next-lesson-link {
  display: block;
  font-size: 1.5rem;
  line-height: 1.8rem;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 15px;
  border-radius: 5px;
  background-color: var(--information);
}

.window-modal.bite-complete a.next-lesson-link small {
  font-size: 1rem;
  font-style: italic;
}

.window-modal.bite-complete a.dashboard-link {
  color: var(--gray-tertiary);
  text-decoration: underline;
}




/* ------------------------------------------------------------ */
/* Translation Overrides */
/* ------------------------------------------------------------ */

.bite-wrapper .translate-toggle-container {
  width: calc(100% + 60px);
  margin: 60px 0 -60px -30px;
  background-color: rgba(255, 255, 255, 0.9);
  background-color: transparent;
}

.bite-wrapper .translate-toggle-container .flex-row:first-of-type {
  padding: 5px 15px;
  border-radius: 50px;
  background-color: rgba(255, 255, 255, 0.9);
}

.bite-wrapper .translate-toggle-container .button.is-loading::after {
  border-left-color: var(--gray-tertiary);
  border-bottom-color: var(--gray-tertiary);
}