

input, select {
  font-size: 100%;
}

.main-body.start,
.main-body.org-start {
  max-width: 100vw;
  padding-top: 0px;
  padding-bottom: 0;
}


.main-body.org-start {
  padding-top: 30px;
}

.main-body-content.start {
  min-width: 348px;
  width: 100%;
}




.start-vertical-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 30px 0;
}

.start-vertical-container {
  width: 100%;
}




/* ---------------------------------- */
/* Unauthed Header */
/* ---------------------------------- */

.unauthed-start-header-container {
  margin-bottom: 60px;
}

.unauthed-start-header-logo {
  width: 130px;
  height: 40px;
  margin-right: 30px;
  background-image: url('../images/FLOW-Lockup.jpg');
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
}

.main-body-content.start .unauthed-start-header-container h1 {
  margin: 30px 0 0px 0;
  text-align: left;
  font-size: 2.75rem;
  line-height: 3rem;
  font-weight: 500;
  color: var(--gray-secondary);
}

.main-body-content.start .unauthed-start-header-container p {
  margin-top: 10px;
  font-size: 1.125rem;
}



@media screen and (max-height: 740px) {

  .unauthed-start-header-container {
    margin-top: 0px;
    margin-bottom: 30px;
  }

  .main-body-content.start .unauthed-start-header-container h1 {
    font-size: 2rem;
    margin: 15px 0 -10px 0;
  }

}




/* ---------------------------------- */
/* Signup / Login form */
/* ---------------------------------- */

.section-auth-form {
  min-height: 485px;
}

.auth-links-container {
  /* margin-bottom: 15px; */
}

p.auth-link-button,
button.auth-link-button {
  margin-right: 2px;
  margin-bottom: -5px;
  padding: 10px 28px;
  color: var(--gray-secondary);
  font-weight: 500;
  font-size: 1.125rem;
  border: 1px solid var(--gray-light);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: var(--gray-light);
}

button.auth-link-button {
  cursor: pointer;

  background-color: var(--white-primary);
}

p.auth-link-button.inactive,
button.auth-link-button.inactive {
  color: var(--gray-inactive);
}

.signup-login-form {
  padding: 30px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: var(--gray-light);
}


.main-body-content.start .signup-login-form input,
#root .main-body-content.start .signup-login-form .react-tel-input input,
.assess-continer input,
.assess-continer .select {
  width: 100%;
  border: 1px solid var(--white-tertiary);
  border-radius: 10px;
  box-shadow: none;
  height: 40px;
}

.main-body-content.start .signup-login-form input.with-padding {
  padding-left: 12px;
  padding-right: 12px;
}

.main-body-content.start .signup-login-form input:focus,
.assess-continer input:focus {
  outline: none;
  border-color: var(--gray-inactive);
  box-shadow: 0 5px 10px 0px rgb(0 0 0 / 15%);
}

/* .main-body-content.start .signup-login-form .react-tel-input input {

} */

.signup-login-form .secondary-helper-button {
  background-color: initial;
}


/* .main-body-content.start .signup-login-form .form-error-header {
  margin-top: 30px;
} */

.main-body-content.start .signup-login-form .form-error-header p {
  margin-bottom: 20px;
  line-height: 1.3rem;
}

.main-body-content.start .signup-login-form .form-error-header a {
  color: var(--error-text);
}


.auth-form-button-container {
  /* margin-top: 30px; */
}

.password-wrapper {
  position: relative;
}

.password-eye {
  justify-content: center;
  position: absolute;
  top: 4px;
  right: 0px;
  height: 36px;
  width: 40px;
  cursor: pointer;
}

input.password-input-with-eye {
  padding-right: 45px;
}







/* Pre-pathway updates below */



/* ---------------------------------- */
/* Header */
/* ---------------------------------- */

.main-body-content.start .start-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-body-content.start .start-header .logo-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 30px;
  gap: 30px;
}

.main-body-content.start .logo {
  width: 190px;
  height: 70px;
  background-image: url('../images/FLOW-Lockup.jpg');
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
}

.main-body-content.start .start-header.with-org .logo {
  width: 135px;
  height: 50px;
}

.main-body-content.start .org-logo {
  width: 135px;
  height: 50px;
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
}

.main-body-content.start .org-logo.simpul-logo {
  height: 80px;
  background-position: center;
  background-size: cover;
}

.main-body-content.start h1 {
  font-size: 1.125rem;
  line-height: 1.6rem;
  font-weight: 400;
  margin-top: 20px;
  margin-bottom: 30px;
  text-align: center;
  color: var(--gray-primary);
}


@media screen and (max-height: 700px) {

  .main-body-content.start .logo {
    width: 119px;
    height: 44px;
  }

  .main-body-content.start h2 {
    font-size: 1.2rem !important;
  }

}



/* ---------------------------------- */
/* General */
/* ---------------------------------- */

/* .main-body-content.start section {
  margin-top: 15px;
  padding: 0 15px;
} */

.main-body-content.start h2 {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 1.4rem;
  text-align: center;
}

/* .main-body-content.start h3 {
  margin-top: 30px;
  font-size: .9rem;
} */

/* .main-body-content.start h3.start-header {
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: 400;
} */

.main-body-content.start h4 {
  font-size: 1rem;
  font-weight: 500;
}

/* .main-body-content.start .input-container {
  min-height: 240px;
} */

.main-body-content.start .input-container.taller {
  min-height: 300px;
}

.main-body-content.start .label,
.main-body-content.start input {
  font-weight: initial;
  font-size: .9rem;
}

.main-body-content.start .field.submit-button {
  margin: 0 60px;
}

.main-body-content.start input {
  margin-bottom: 15px;
  box-shadow: 0 0 5px 0px rgb(0 0 0 / 10%);
  border-color: var(--gray-tertiary);
  background-color: var(--white-primary);
}

.main-body-content.start input::placeholder {
  color: var(--gray-tertiary)
}

.main-body-content.start .label {
  margin-bottom: 3px;
}

.main-body-content.start a {
  /* color: var(--active); */
  color: var(--gray-tertiary);
  text-decoration: underline;
}

.main-body-content.start a:hover {
  color: var(--gray-secondary);
}

.main-body-content.start .login-secondary-action a,
.main-body-content.start .login-secondary-action button {
  padding: 10px 5px;
  font-size: .9rem;
  /* color: var(--active); */
  cursor: pointer;
}

.main-body-content.start a:hover,
.main-body-content.start .login-secondary-action button {
  text-decoration: underline;
}

.main-body-content.start .checkbox {
  display: flex;
  align-items: center;
  font-size: .8rem;
}

.main-body-content.start .checkbox input {
  margin-right: 10px;
}

.main-body-content.start .button {
  /* width: 100%; */
  /* margin: 15px 0; */
  margin: 0px 0px 15px 0;
  border: initial;
  background-color: var(--active);
  color: var(--white-primary);
  font-size: 1.25rem;
  font-weight: 500;
}

.main-body-content.start .button.forgot-password {
  font-size: 1rem;
  font-weight: initial;
}

.main-body-content.start .loading-general .button {
  background: transparent;
}

.main-body-content.start .login-secondary-action {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: .9rem;
  color: var(--gray-tertiary);
}

.main-body-content.start .form-error {
  color: var(--error-text);
}

.main-body-content.start .form-error-header {
  min-height: 24px;
  margin: 5px 0;
  text-align: center;
}

.main-body-content.start span.button.as-checkbox {
  display: inline-block;
  width: auto;
  background-color: var(--gray-light);
  color: var(--gray-primary);
  border-radius: 20px;
  margin: 5px 5px 5px 0;
  font-size: .9rem;
  font-weight: initial;
}

.main-body-content.start span.button.as-checkbox.selected {
  background-color: var(--white-tertiary);
}

.main-body-content.start .top-message {
  margin-bottom: 45px;
}

.main-body-content.start .top-message p {
  text-align: center;
}


/* ---------------------------------- */
/* Org Start Org Contact */
/* ---------------------------------- */

.org-contact-container {
  margin: 30px 0 15px 0;
  padding: 15px;
  border: 1px solid var(--white-tertiary);
  border-radius: 5px;
  background-color: var(--white-primary);
}

.org-contact-phone-container {
  margin-top: 15px;
}



/* ---------------------------------- */
/* Welcome Screens */
/* ---------------------------------- */

.main-body-content.start.welcome h1 {
  margin-bottom: 0px;
}

.welcome-question-wrapper {
  margin-top: 20px;
}

.welcome-question-wrapper h4 {
  text-align: center;
}

.welcome-question-answers {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 10px;
}

.welcome-question-answers button {
  background-color: var(--gray-light);
  padding: 9px 12px;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.welcome-question-answers button.selected {
  background-color: var(--gray-secondary);
  color: var(--white-primary);
}


/* Responsive Layout */
@media screen and (min-width: 500px) {

  .main-body-content.start {
    margin: 0 auto;
    max-width: 500px;
  }

}

@media screen and (min-height: 850px) {

  .main-body-content.start h1 {
    font-size: 1.3rem;
    margin-top: 30px;
  }

  .main-body-content.start .button {
    /* margin: 30px 0; */
    margin: 0px 0px 15px 0px;
  }
}

@media screen and (max-height: 700px) {

  .main-body-content.start h1 {
    font-size: 1.15rem;
    max-width: 300px;
    margin-bottom: 15px;
  }

  .main-body-content.start h4 {
    font-size: .9rem;
  }

  .main-body-content.start .button {
    margin: 10px 0;
  }

  .main-body-content.start .form-error-header.form-error {
    font-size: .9rem;
  }

}




/* ---------------------------------- */
/* Mic Test */
/* ---------------------------------- */

.welcome-mic-test-container {
  min-height: 600px;
}

.mic-test-wrapper .question-wrapper {
  margin-left: 0px;
  margin-right: 30px;
}

.mic-test-help-wrapper {
  display: flex;
  align-items: flex-end;
  margin-left: 15px;
}

.mic-test-help-wrapper p {
  color: var(--active);
  font-style: italic;
}

.mic-test-help-wrapper .fun-arrow {
  width: 50px;
  height: 50px;
  margin-bottom: 7px;

  background-image: url('../images/icons/icon-fun-arrow.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.main-body-content.start.tutorial .button {
  margin-top: 90px;
}

.help-reload {
  text-decoration: underline;
}

.tutorial-privacy-statement {
  padding: 15px;
  font-size: .9rem;
  text-align: center;
  border-radius: 5px;
  /* background-color: var(--gray-light); */
}

p.special-instructions {
  margin: 15px 15px 0 15px;
  font-size: .9rem;
}

p.special-instructions i {
  color: var(--active);
}

.allow-image-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

img.allow-image {
  width: 225px;
  margin-top: 30px;
}

img.allow-image.big {
  width: 300px;
}

img.allow-image.small {
  width: 175px;
}

img.allow-image.with-border {
  border: 1px solid var(--white-tertiary);
  border-radius: 5px;
}

.instagram-message {
  text-align: center;
  font-size: .9rem;
  font-style: italic;
}

.instagram-message span {
  display: block;
  margin-bottom: 15px;
}

.very-special-text {
  font-size: .8rem;
  color: #ffffff;
  margin-top: 30px;
}

.very-special-text.active {
  color: var(--gray-primary);
}


/* ---------------------------------- */
/* Insta / Facebook Modal */
/* ---------------------------------- */

.window-modal.register-help {
  width: 85%;
}

.window-modal.register-help ul {
  list-style: disc;
  margin-left: 20px;
}

.window-modal.register-help .how-to-image {
  width: 100%;
  height: 280px;
  margin-top: 15px;
  background-image: url('../images/facebook_insta_how_to.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}





/* ---------------------------------- */
/* Choose Package */
/* ---------------------------------- */

.main-body-content.start.choose-package a.action-button {
  color: var(--white-primary);
}

.main-body-content.start.choose-package .login-secondary-action {
  margin-top: 30px;
}

.main-body-content.start.choose-package .purchase-steps-wrapper a {
  color: var(--gray-primary);
}




/* ---------------------------------- */
/* Package and purchase */
/* ---------------------------------- */

.choose-package-button-wrapper.onboarding {
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
}

.choose-package-button-wrapper.onboarding .button-wrapper {
  width: 100%;
}

.choose-package-button-wrapper.onboarding button.choose-package-button {
  width: 48%;
}

.choose-package-button-wrapper.onboarding .descriptor {
  font-size: .9rem;
  color: var(--gray-tertiary);
  text-align: center;
  margin-top: 5px;
}

.choose-package-button-wrapper.onboarding .package-title {
  font-size: 1.1rem;
  margin-bottom: 15px;
}

.choose-package-button-wrapper.onboarding .package-price {
  font-size: .9rem;
  font-weight: 400;
}

.choose-package-button-wrapper.onboarding button.choose-package-button .package-discount {
  position: initial;
  display: inline-block;
  margin-top: 5px;
  padding: 3px 8px;
}

/* .choose-package-button-wrapper.onboarding .choose-package-button .package-price {
  margin-bottom: 20px;
} */

/* .choose-package-button-wrapper.onboarding .package-price small {
  font-size: .8rem;
  margin-top: 3px;
  display: block;
} */

.choose-package-button-wrapper.onboarding .choose-package-button h5 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 49px;
  padding: 0px;
  font-weight: normal;
}

.choose-package-button-wrapper.onboarding .choose-package-button h5 span {
  font-weight: 600;
}

.choose-package-button-wrapper.onboarding .button-wrapper:nth-of-type(2) .choose-package-button {
  margin-bottom: 14px;
}

.goal-text-wrapper {
  margin-top: 10px;
}

.goal-text-wrapper strong {
  text-transform: capitalize;
}

.main-body-content.start.welcome .package-details-wrapper {
  margin-bottom: 30px;
}

.main-body-content.start.welcome .package-details-wrapper h2 {
  font-size: 1.25rem;
  text-align: center;
  margin-bottom: 10px;
}

.main-body-content.start.welcome .package-details-wrapper ul {
  margin: 0px 45px;
  list-style: disc;
}

.main-body-content.start input.input-promo-code {
  margin-bottom: initial;
}

/* .main-body-content.start.welcome .package-promo-code-wrapper {
  margin-top: 15px;
} */

/* .main-body-content.start.welcome .package-promo-code-wrapper button#apply-promo-code {
  width: auto;
  margin-top: 0px;
  margin-bottom: 0px;
} */

/* .main-body-content.start.welcome #promo-code-discount-description {
  color: var(--gray-tertiary);
  font-style: italic;
  font-size: .9rem;
  margin-top: 3px;
  margin-left: 5px;
} */

.main-body-content.start .action-button-wrapper {
  margin-top: initial;
}

.main-body-content.start.welcome .action-button-wrapper {
  margin-top: 15px;
}

.purchase-back-button-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 45px;
}

.purchase-back-button-wrapper button {
  color: var(--gray-tertiary);
  font-size: .9rem;
  margin-top: 10px;
  cursor: pointer;
}

.main-body-content.start.welcome .loading-general {
  padding: 15px;
}

.main-body-content.start.welcome .loading-general .button {
  background-color: transparent;
}

.verify-welcome-text {
  font-size: 1.125rem;
}

.verify-welcome-text strong {
  font-size: 1.75rem;
}

.community-membership-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.community-membership-button {
  color: var(--gray-tertiary);
  cursor: pointer;
}

.community-membership-button p strong {
  font-size: 1.125rem;
  line-height: 1.75rem;
}


@media screen and (max-height: 700px) {

  .choose-package-button-wrapper.onboarding .package-title {
    font-size: 1rem;
  }

}


/* ---------------------------------- */
/* Certificate Styles */
/* ---------------------------------- */

.cert-header {
  margin-bottom: 15px;
  text-align: center;
}

.cert-header p {
  font-size: 1rem;
  font-style: italic;
}

.cert-header h3 {
  font-size: 1.125rem;
  font-weight: normal;
  margin-top: 15px;
}

.main-body-content.start .cert-header h1 {
  margin-top: 5px;
  font-weight: 500;
  color: var(--gray-primary);
}



.cert-register-title-container {
  width: 100%;
  text-align: center;
}

.main-body-content.start .cert-register-title-container h2 {
  margin-top: 5px;
}

.cert-register-steps-container {
  display: flex;
  justify-content: space-around;
}

.cert-header p.cert-register-step,
.cert-register-step {
  width: 100%;
  font-size: .8rem;
  padding: 5px;
  border-bottom: 1px solid var(--white-tertiary);
}

.cert-register-step:nth-of-type(2) {
  margin-left: 10px;
  margin-right: 10px;
}

.cert-header p.cert-register-step.active-step,
.cert-register-step.active-step {
  border-bottom-color: var(--active);
}


/* Responsive Layout */
/* @media screen and (max-width: 380px) {

  .cert-register-step {
    font-size: .8rem;
  }

} */

/* ---------------------------------- */
/* Start Left Column */
/* ---------------------------------- */


.start-left-column {
  display: none;
}



/* Responsive Layout */
@media screen and (min-width: 675px) {

  .main-body.start.with-start-left-column {
    display: flex;
    flex-direction: row;
  }

  .main-body.start.with-start-left-column .main-body-content.start  {
    margin: initial;
  }

  .start-left-column {
    display: block;
    width: 45%;
    min-width: 300px;
    max-width: 600px;
    height: 100vh;
    padding: 30px 0 30px 30px;
  }

  .image-background {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .image-background.certificate-course {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: var(--white-primary);
    background-image: url('https://files.flowspeak.io/images/certificate-course-background3.jpg');
  }

  .image-background.start-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    position: fixed;
    top: 30px;
    left: 30px;
    height: calc(100% - 60px);
    width: calc(45% - 45px);
    max-width: 600px;

    text-align: center;
    color: var(--white-primary);
    background-image: url('https://files.flowspeak.io/images/splashcreen_large_compressed.jpg');
  }

 .image-background.certificate-course p:nth-of-type(1) {
    font-size: 1rem;
  }

  .image-background.certificate-course p:nth-of-type(2) {
    font-size: 1.5rem;
    font-weight: 500;
  }

}


@media screen and (max-height: 750px) {

  .image-background.start-image {
    position: fixed;
    top: 30px;
    left: 30px;
    height: calc(100% - 60px);
    width: calc(45% - 45px);
  }

}


@media screen and (min-width: 850px) {

  .image-background.certificate-course p:nth-of-type(1) {
    font-size: 1.125rem;
  }

  .image-background.certificate-course p:nth-of-type(2) {
    font-size: 2rem;
    font-weight: 500;
  }

}





.org-register-welcome h3 {
  font-size: 1.2rem;
}

.org-register-welcome p {
  font-size: 1.1rem;
}







/* ---------------------------------- */
/* Launch */
/* ---------------------------------- */

.main-body-content.start.launch {
  margin-top: 60px;
}

.main-body-content.start.launch .launch-logo {
  width: 300px;
  height: 150px;
  margin-bottom: 60px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.main-body-content.start.launch h1 {
  margin-top: 15px;
  font-size: 1.5rem;
  font-weight: 500;
}

.main-body-content.start.launch .loading-general {
  padding-top: 0px;
}

.main-body-content.start.launch .loading-general .button.is-loading {
  margin-bottom: 5px;
}

.main-body-content.start.launch .error-message {
  margin-top: 45px;
  color: var(--error-text);
  text-align: center;
}




/* ---------------------------------- */
/* Choose Course */
/* ---------------------------------- */

button.choose-package-button.choose-course {
  width: 100%;
}

button.choose-package-button.choose-course .package-title {
  margin-bottom: 10px;
}

button.choose-package-button.choose-course .package-price {
  font-family: 'Roboto', sans-serif;
}





/* ---------------------------------- */
/* Set Password & Activate Account */
/* ---------------------------------- */

.main-body.set-password {
  max-width: 100%;
}

.main-body.set-password h4 {
  margin-top: 45px;
  text-align: center;
}

.main-body.set-password .field.submit-button {
  text-align: center;
}


@media screen and (min-width: 700px) {

  .main-body.set-password {
    padding-top: 100px;
  }

}