
/* ------------------------------------------------------------ */
/* Layout */
/* ------------------------------------------------------------ */

.main-body-content.packages,
.main-body-content.purchase,
.main-body-content.purchase-complete {
  max-width: 800px;
}


.choose-package-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.choose-package-button-wrapper {
  width: 100%;
}



/* ------------------------------------------------------------ */
/* Packages Modal */
/* ------------------------------------------------------------ */

.packages-modal {
  position: fixed;
  z-index: 4000;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
}

.packages-modal-content {
  width: 90%;
  max-width: 400px;
  height: 80%;
  min-height: 590px;
  max-height: 700px;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 5px 10px -1px rgb(0 0 0 / 40%);
  background-color: var(--white-primary);
}

.packages-modal-content .modal-header {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 15px;
  /* margin-bottom: 30px; */
}

.packages-modal-content .modal-header .icon {
  width: 16px;
  height: 16px;
}

.packages-modal-content .modal-header .modal-title {
  font-size: 1.25rem;
  font-weight: 300;
  line-height: 1.6rem;
  text-align: center;
}

.packages-modal-content .modal-header .modal-title strong {
  font-size: 1.5rem;
  font-weight: 500;
}

.packages-modal-content .packages-details {
  margin-bottom: 30px;
}

.packages-modal-content .packages-details p {
  text-align: center;
  margin-bottom: 5px;
}

.packages-modal-content .packages-details ul,
.package-summary-wrapper ul,
.choose-package-wrapper .packages-details ul {
  font-weight: 300;
  margin-left: 30px;
  list-style-type: disc;
}

.packages-modal-content .packages-details ul strong,
.package-summary-wrapper ul strong,
.choose-package-wrapper .packages-details ul strong {
  font-weight: 500;
}

.choose-package-wrapper .packages-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}

.choose-package-wrapper .packages-details p {
  text-align: center;
  margin-bottom: 10px;
}


.choose-package-wrapper .action-button-wrapper {
  margin-top: 15px;
}



@media screen and (max-width: 370px) {

  .choose-package-wrapper .packages-details {
    font-size: .9rem;
  }

}



/* ------------------------------------------------------------ */
/* Choose Package Buttons */
/* ------------------------------------------------------------ */


.choose-package-button-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-around; */
  /* margin-bottom: 30px; */
}

button.choose-package-button {
  position: relative;
  width: 100%;
  padding: 15px 20px;
  margin-bottom: 15px;
  font-family: 'Outfit', sans-serif;
  color: var(--gray-primary);
  border-radius: 10px;
  border: 2px solid var(--white-tertiary);
  background-color: transparent;
  cursor: pointer;
  text-align: left;
}

button.choose-package-button.selected {
  color: var(--gray-primary);
  border-color: var(--active);
  box-shadow: 0 5px 10px -1px rgb(0 0 0 / 40%);
}

button.choose-package-button h5 {
  font-size: .9rem;
  font-weight: 600;
  padding: 15px 5px 0 5px;
  pointer-events: none;
}

button.choose-package-button .package-title {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 5px;
}

button.choose-package-button .package-price {
  font-size: 1rem;
}

button.choose-package-button .package-discount {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 10px;
  color: var(--white-primary);
  border-radius: 5px;
  background-color: var(--active);
}

.package-form .action-button-wrapper {
  margin-top: 30px;
}


@media screen and (min-width: 545px) {

  .choose-package-button-wrapper {
    flex-direction: row;
    align-items: stretch;
    justify-content: space-between;
  }

  button.choose-package-button {
    width: 32%;
    padding-right: 40px;
  }

  button.choose-package-button .package-title {
    margin-bottom: 15px;
  }

}


@media screen and (max-height: 700px) {

  button.choose-package-button {
    padding: 8px 15px;
  }

}




/* ------------------------------------------------------------ */
/* Purchase Steps */
/* ------------------------------------------------------------ */

.purchase-steps-wrapper {
  margin: -10px 0 30px 0;
}

.purchase-steps-bar-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.bar-spacer {
  width: 16.5%;
  height: 5px;
  border-top: 5px solid transparent;
}

.bar {
  flex: 1;
  height: 5px;
  border-top-width: 2px;
  border-color: var(--active);
  border-bottom-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}

.bar.solid {
  border-style: solid;
}

.bar.dashed {
  border-style: dashed;
}


.purchase-steps-dots-wrapper {
  display: flex;
}

.step-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: .8rem;
  flex: 0 1 33.333%;
  margin-top: -14px;
}

.step-wrapper a {
  color: var(--gray-primary);
}

.dot {
  width: 20px;
  height: 20px;
  margin-bottom: 10px;
  border: 2px solid var(--active);
  border-radius: 12px;
  background-color: var(--white-primary);
}

.dot.full {
  border: none;
  background-color: var(--active);
}





/* ------------------------------------------------------------ */
/* Package Summary */
/* ------------------------------------------------------------ */

.package-summary-wrapper {
  margin-bottom: 30px;
}

/* .package-summary-wrapper .sub-line {
  text-align: center;
  margin-top: 10px;
  font-style: italic;
  font-size: .7rem;
} */

.package-summary {
  padding: 15px;
  border: 1px solid var(--active-alt);
  border-radius: 5px;
  box-shadow: 0 0 5px 0px rgb(0 0 0 / 25%);
}

.summary-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.summary-header-plan-name {
  display: flex;
  align-items: center;
}

.summary-header-plan-name h5 {
  font-weight: 500;
}

.summary-price {
  font-size: 1.25rem;
  font-weight: 500;
}

.package-summary-wrapper ul {
  font-size: .9rem;
}

.package-summary .package-line {
  justify-content: space-between;
  font-size: .9rem;
}





/* ------------------------------------------------------------ */
/* Promo Code */
/* ------------------------------------------------------------ */

.package-promo-code-wrapper {
  margin-bottom: 15px;
  width: 100%;
  max-width: 400px;
}

.package-promo-code-wrapper .promo-input-container {
  display: flex;
}

.package-promo-code-wrapper label {
  display: block;
  color: var(--gray-tertiary);
}

.package-promo-code-wrapper input.input {
  font-size: .9rem;
}

.package-promo-code-wrapper input.input.input-promo-code::placeholder {
  color: var(--gray-tertiary);
}

.package-promo-code-wrapper button.apply-promo-code {
  font-size: .9rem;
  margin-left: 15px;
  padding: 5px 10px;
  border: 1px solid var(--gray-tertiary);
  border-radius: 5px;
  color: var(--gray-tertiary);
  background-color: var(--white-primary);
  cursor: pointer;
}

.package-promo-code-wrapper button.button:hover,
.package-promo-code-wrapper button.apply-promo-code:hover {
  box-shadow: 0 0 5px 0px rgb(0 0 0 / 25%);
}

.package-promo-code-wrapper button.apply-promo-code:active {
  box-shadow: 0 0 5px 0px rgb(0 0 0 / 50%);
}


.package-promo-code-wrapper button.remove-promo-code {
  font-size: .9rem;
  color: var(--gray-tertiary);
  margin-left: 15px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.promo-code-discount-description {
  color: var(--gray-tertiary);
  font-style: italic;
  font-size: .9rem;
  margin-top: 3px;
  margin-left: 5px;
}



/* ------------------------------------------------------------ */
/* Package Total */
/* ------------------------------------------------------------ */

.package-total-wrapper {
  padding-top: 15px;
  border-top: 1px solid var(--white-tertiary);
}

.package-line {
  display: flex;
  justify-content: flex-end;
}

.package-line i {
  /* color: var(--active-alt); */
}

.package-line-title {

}

.package-line-price {
  width: 70px;
  text-align: right;
}

.package-total-title {
  font-size: 1.125rem;
  font-weight: 500;
  margin-top: 10px;
}

.package-total-price {
  width: 70px;
  text-align: right;
  font-size: 1.125rem;
  margin-top: 10px;
}



/* ------------------------------------------------------------ */
/* Purchase Summary */
/* ------------------------------------------------------------ */

.purchase-summary-wrapper {
  width: 100%;
  /* margin-bottom: 30px; */
  margin-bottom: 15px;
}

.purchase-summary-wrapper .package-line.total {
  /* margin-top: 15px; */
  margin-top: 5px;
  padding-top: 10px;
  border-top: 1px solid var(--white-tertiary);
}

.main-body-content.purchase .summary-price {
  font-size: initial;
  font-weight: initial;
}



/* ------------------------------------------------------------ */
/* Stripe Form */
/* ------------------------------------------------------------ */

.package-form .StripeElement {
  min-height: 160px;
}



/* ------------------------------------------------------------ */
/* Payment Complete */
/* ------------------------------------------------------------ */

.payment-complete-success-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.payment-complete-success-wrapper h3,
.payment-complete-failed-wrapper h3 {
  font-size: 1.25rem;
  margin: 30px 0;
}

.payment-complete-success-wrapper p {
  margin-bottom: 15px;
}





/* ------------------------------------------------------------ */
/* Membership */
/* ------------------------------------------------------------ */

.main-body-content.membership {

}

.main-body-content.membership div {
  margin-bottom: 30px;
}

.main-body-content.membership ul {
  margin-left: 30px;
  list-style: disc;
}

.main-body-content.membership ul li {

}

/* .main-body-content.membership p {
  margin-bottom: 20px;
} */

/* .main-body-content.membership strong {
  color: var(--active);
} */


/* ------------------------------------------------------------ */
/* Misc */
/* ------------------------------------------------------------ */

.main-body-content.packages .loading-general,
.main-body-content.purchase .loading-general {
  padding-top: 15px;
  padding-bottom: 15px;
}

.payment-error-message {
  padding: 5px;
  margin: 30px 45px;
  color: var(--white-primary);
  text-align: center;
  border-radius: 5px;
  background-color: var(--error-text-alt);
}


.purchase-cancel-link-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 45px 0;
}
.purchase-cancel-link {
  color: var(--gray-tertiary);
}

.package-summary-wrapper .sub-line,
.purchase-summary-wrapper .sub-line,
.choose-package-wrapper .action-button-wrapper .sub-line {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-style: italic;
  font-size: .7rem;
}

.choose-package-wrapper .action-button-wrapper .sub-line {
  font-size: .8rem;
}



/* ------------------------------------------------------------ */
/* Responsive */
/* ------------------------------------------------------------ */

@media screen and (max-width: 380px) {

  h1.h1-smaller {
    font-size: 2rem;
  }

}


@media screen and (max-width: 380px) and (max-height: 730px) {

  /* .header {
    height: 90px;
    min-height: 90px;
  } */

  h1.h1-smaller,
  .title-wrapper h1 {
    font-size: 1.75rem;
  }

  .action-button-wrapper {
    margin-top: 15px;
  }

  a.action-button,
  button.button.action-button {
    font-size: 1rem;
  }

  .purchase-cancel-link-wrapper {
    margin-top: 15px;
  }

  .purchase-steps-wrapper {
    margin-top: 0px;
    /* margin-bottom: 15px; */
  }

  .package-summary-wrapper {
    margin-bottom: 15px;
  }

  .package-promo-code-wrapper {
    margin-bottom: 15px;
  }

  .summary-price {
    font-size: 1rem;
  }



  .main-body-content.start.welcome.choose-package .purchase-steps-wrapper {
    margin-bottom: 15px;
  }

  .main-body-content.start.welcome.choose-package .packages-details {
    font-size: .9rem;
    margin-top: 15px;
  }

  .main-body-content.start.welcome.choose-package .login-secondary-action,
  .main-body-content.start.welcome.choose-package .action-button-wrapper {
    margin-top: 15px;
  }

}

.promo-code-acknowledgement-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.promo-code-acknowledgement {
  text-align: center;
  font-size: .9rem;
  max-width: 350px;
  color: var(--white-primary);
  padding: 10px;
  border-radius: 5px;
  background-color: var(--active-alt);
}

.promo-code-acknowledgement span {
  font-weight: 500;
}