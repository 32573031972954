
/* ------------------------------------------------------------ */
/* Base Layout */
/* ------------------------------------------------------------ */


.wrapper.progress-screen {
  background-color: var(--gray-background);
}

.wrapper.progress-screen .main-container {
  min-height: 100vh;
  width: 100%;
  max-width: 800px;
  background-color: var(--white-primary);
  border-left: 1px solid var(--white-tertiary);
  border-right: 1px solid var(--white-tertiary);
}

.wrapper.progress-screen .main-body {
  padding-top: 150px;
}

.wrapper.progress-screen .main-body.with-sub-nav {
  padding-top: 210px;
}

.wrapper.progress-screen .main-body-content {
  padding-left: initial;
  padding-right: initial;
}


@media screen and (max-width: 700px) {

  .wrapper.progress-screen .main-container {
    border-left: none;
    border-right: none;
  }

}



/* ------------------------------------------------------------ */
/* Helpers */
/* ------------------------------------------------------------ */

.stat-container {
  width: 100%;
  padding: 45px 30px;
  text-align: center;
  border-bottom: 1px solid var(--white-tertiary);
}

.stat-container h3 {
  margin-bottom: 15px;
}

.wrapper.progress-screen h3 {
  font-family: 'Outfit', sans-serif;
  font-size: 1.5rem;
}



/* ------------------------------------------------------------ */
/* Progress Header */
/* ------------------------------------------------------------ */

.progress-header-wrapper {
  height: 150px;
  width: 100%;
  max-width: 799px;
  position: fixed;
  z-index: 1000;
  border-right: 1px solid var(--white-tertiary);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* background-image: url('../images/expression-background.jpg'); */
  background-image: url('../images/purple-background-grain-darker.jpg');
}

.progress-header-container {
  width: 100%;
  height: 100%;
}

.progress-header-container h3,
.progress-header-container h5 {
  color: var(--white-primary);
}

.progress-header-container h5 {
  font-size: 1.125rem;
  text-align: center;
}

.progress-header-wrapper .icon-more {
  background-image: url('../images/icons/icons-nav/more-light.png');
}



@media screen and (max-width: 700px) {

  .progress-header-container .stacked-nav-opener {
    display: none;
  }

}


/* ------------------------------------------------------------ */
/* Progress Subnav */
/* ------------------------------------------------------------ */

.progress-subnav-wrapper {
  position: fixed;
  top: 150px;
  z-index: 100;
  justify-content: center;
  width: 100%;
  max-width: 799px;
  height: 60px;
  border-right: 1px solid var(--white-tertiary);
  border-bottom: 1px solid var(--white-tertiary);
  background-color: var(--white-primary);
}

.progress-subnav-container {

}

.progress-subnav-container a {
  padding: 5px 20px;
  font-family: 'Outfit', sans-serif;
  color: var(--gray-primary);
  border-radius: 30px;
}

.progress-subnav-container a.active {
  color: var(--white-primary);
  background-color: var(--gray-primary);
}



@media screen and (max-width: 800px) {

  .progress-header-wrapper,
  .progress-subnav-wrapper {
    width: 100%;
    border-right: none;
  }

}


/* ------------------------------------------------------------ */
/* Stats Challenge */
/* ------------------------------------------------------------ */

.stat-challenge-container {
  padding-top: 15px;
  padding-bottom: 30px;
  background-color: var(--gray-light);
}


/* ------------------------------------------------------------ */
/* Stats Activity */
/* ------------------------------------------------------------ */

.stats-activity-wrapper {
  width: 100%;
  min-width: 300px;
  max-width: 360px;
}

.stats-activity-row {
  display: flex;
  justify-content: space-between;
}

.stats-activity-row .streak-text {
  width: 100%;
  text-align: center;
  font-style: italic;
  font-size: .8rem;
  color: var(--gray-tertiary);
}

@property --opacity{
  syntax: '<number>';
  inherits: true;
  initial-value: 1;
}

.stats-activity-row .activity-circle {
  --opacity: 0;

  width: 13px;
  height: 13px;
  margin-bottom: 13px;
  border-radius: 50%;
  opacity: var(--opacity);
  background-color: var(--active);
}

.stats-activity-row .activity-circle.empty {
  opacity: 1;
  background-color: var(--white-tertiary);
}

.activity-circle-today-indicator {
  position: absolute;
  top: -5px;
  left: -18.5px;
  width: 50px;
  font-size: .8rem;
  color: var(--gray-inactive);
}

.activity-circle-today-indicator div:first-of-type {
  width: 23px;
  height: 23px;
  border: 1px solid var(--gray-inactive);
  border-radius: 15px;
}

.activity-circle-today-indicator div:last-of-type {
  /* width: 10px; */
  width: 100%;
  height: 10px;
}

.activity-circle-today-indicator div:last-of-type span {
  display: block;
  width: 26px;
  height: 10px;
  border-right: 1px solid var(--gray-inactive);
}


/* ------------------------------------------------------------ */
/* Stats Badges */
/* ------------------------------------------------------------ */

.stats-badges-container {
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  width: 100%;
}

.badge-wrapper {
  width: 18%;
  padding: initial;
  margin: 8px;
  border: 1px solid var(--white-tertiary);
  border-radius: 5px;
}

.badge-wrapper.disabled {
  border-style: dashed;
}

.stat-badge-image-container {
  padding: 10px;
}

.issue-date-container {
  width: 100%;
  padding: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: var(--gray-light);
}

.issue-date-container p {
  font-size: .9rem;
  color: var(--gray-tertiary);
}

.badge-image.grayscale {
  opacity: .65;
}

.badge-image.grayscale:hover {
  opacity: .9;
}

.window-modal-body .badge-image.grayscale {
  opacity: 1;
}


@media screen and (max-width: 775px) {

  .badge-wrapper {
    width: 22%;
  }

}

@media screen and (max-width: 700px) {

  .badge-wrapper {
    width: 30%;
  }

}

@media screen and (max-width: 450px) {

  .badge-wrapper {
    width: 40%;
  }

}



/* ------------------------------------------------------------ */
/* Stats Pathways */
/* ------------------------------------------------------------ */

.stats-pathways-wrapper .pathway-wrapper {
  background-color: var(--gray-extra-light);
  border-color: var(--white-tertiary);
  cursor: initial;
}

.stats-pathways-wrapper .pathway-wrapper.selected {
  border-color: var(--active);
  background-color: #FDF1FA;
}

.stats-pathways-wrapper .pathway-wrapper h3,
h4.h4-pathway-stat {
  text-align: center;
  font-size: 1.25rem;
  font-weight: 400;
}

h4.h4-pathway-stat {
  margin-bottom: 15px;
}

.stats-pathways-wrapper .badge-wrapper {
  width: 25%;
}


/* ------------------------------------------------------------ */
/* Stats Courses */
/* ------------------------------------------------------------ */

.stats-courses-wrapper {
  width: 100%;
}

.stats-courses-container {
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
}

.stats-course-container {
  width: 30%;
  padding: initial;
  margin: 8px;
  border: 1px solid var(--white-tertiary);
  border-radius: 5px;
}

.stats-course-container.placeholder {
  width: 20%;
  min-width: 100px;
  padding: 30px;
  border-style: dashed;
}

.stats-course-container.placeholder .vector-icon {
  opacity: .75;
}

.stats-course-detail {
  flex-grow: 1;
  padding: 15px;
}

.stats-course-detail h5,
.stats-certificate-detail h5 {
  margin-top: 5px;
  font-weight: 500;
  text-align: center;
}


@media screen and (max-width: 645px) {

  .stats-course-container {
    width: 40%;
  }

}

@media screen and (max-width: 450px) {

  .stats-course-container {
    width: 100%;
  }

  .stats-course-container.placeholder {
    width: 40%;
  }

}




/* ------------------------------------------------------------ */
/* Stats Certificates */
/* ------------------------------------------------------------ */

.stats-certificates-wrapper {
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  width: 70%;
}

.stats-certificate-container {
  padding: initial;
  margin-bottom: 15px;
  border: 1px solid var(--white-tertiary);
  border-radius: 5px;
}

.stats-certificate-container.placeholder {
  margin: 10px;
  border-style: dashed;
}

.stats-certificate-detail {
  padding: 30px;
  text-align: center;
}

.stats-certificate-detail .icon {
  width: 50px;
  height: 50px;
}

.stats-certificate-download-link {
  display: inline-block;
  padding: 0 0 15px 0;
  color: var(--gray-tertiary);
  text-decoration: underline;
}

.stats-certificate-share-band {
  margin-top: 15px;
  padding-bottom: 15px;
  border-top: 1px solid var(--white-tertiary);
}

button.copy-share-link-button {
  margin-top: 15px;
  font-size: .9rem;
  text-decoration: underline;
  color: var(--gray-tertiary);
}


@media screen and (max-width: 525px) {

  .stats-certificates-wrapper {
    width: 100%;
  }

}








/* ------------------------------------------------------------ */
/* Stats All Time */
/* ------------------------------------------------------------ */

.stats-all-time-row {
  justify-content: center;
  text-align: center;
}

.stats-all-time-row .stat-block {
  flex-shrink: 0;
  width: 120px;
  padding: 15px;
  margin: 8px;
  border-radius: 5px;
  border: 1px solid var(--gray-primary);
}

.stats-all-time-row .stat-block h2,
.stats-all-time-row .stat-block h3 {
  font-family: 'Outfit', sans-serif;
  margin-bottom: 0px;
}

.stats-all-time-row .stat-block p {
  font-weight: 300;
  line-height: 1.25rem;
  margin-bottom: 0px;
}


@media screen and (max-width: 450px) {

  .stats-all-time-row .stat-block {
    width: 100px;
    padding: 10;
    margin: 5px;
  }

  .stats-all-time-row .stat-block p {
    font-size: .9rem;
  }

}



/* ------------------------------------------------------------ */
/* Stats Graphs */
/* ------------------------------------------------------------ */

.stat-container .graph-holder {
  height: 300px;
}


@media screen and (max-width: 550px) {

  .stat-container .graph-holder {
    height: 200px;
  }

}





/* ------------------------------------------------------------ */
/* Progress Lessons */
/* ------------------------------------------------------------ */

.wrapper.progress-screen .lesson-graph-wrapper {
  position: relative;
  width: 400px;
  height: 250px;
  margin: 30px;
}

.wrapper.progress-screen .lesson-graph-wrapper .graph-holder {
  height: 100%;
}

.wrapper.progress-screen .lesson-graph-wrapper .lesson-count-summary {
  position: absolute;
  top: 90px;
  left: 83px;
  width: 120px;
}

.wrapper.progress-screen .lesson-graph-wrapper .lesson-count-summary h2 {
  line-height: 2rem;
}

.wrapper.progress-screen .lesson-graph-wrapper .lesson-count-summary p {
  font-size: .8rem;
}


.wrapper.progress-screen .lessons-list-wrapper {
  flex-wrap: wrap;
  margin: 30px 30px 60px 30px;
}

.wrapper.progress-screen .lessons-list-wrapper .lesson-card-details-container {
  background-color: var(--gray-light);
}

.lesson-graph-wrapper .lesson-count-summary {
  position: absolute;
  top: 97px;
  left: 35px;
  width: 120px;
  text-align: center;
}


@media screen and (max-width: 450px) {

  .wrapper.progress-screen .lesson-graph-wrapper {
    width: 300px;
    height: 200px;
  }

  .wrapper.progress-screen .lesson-graph-wrapper .lesson-count-summary {
    top: 67px;
    left: 35px;
    line-height: 1rem;
  }

  .wrapper.progress-screen .lesson-graph-wrapper .lesson-count-summary h2 {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }

}



/* ------------------------------------------------------------ */
/* Short Screens */
/* ------------------------------------------------------------ */

@media screen and (max-height: 700px) {

  .wrapper.progress-screen .main-body {
    padding-top: 120px;
  }

  .wrapper.progress-screen .main-body.with-sub-nav {
    padding-top: 160px;
  }

  .progress-header-wrapper {
    height: 120px;
  }

  .wrapper.progress-screen .progress-header-wrapper h3 {
    font-size: 1.25rem;
  }

  .progress-header-wrapper h5 {
    font-size: 1rem;
  }

  .progress-subnav-wrapper {
    top: 120px;
    height: 40px;
  }

  .progress-subnav-wrapper a {
    padding: 3px 15px;
    font-size: .9rem;
  }

  .stat-container {
    padding: 30px;
  }

  .stat-challenge-container {
    padding: 0 15px 15px 15px;
  }

}





/* ------------------------------------------------------------ */
/* Lesson Details screen */
/* ------------------------------------------------------------ */

.lesson-detail-header {
  padding: 30px;
  border-bottom: 1px solid var(--white-tertiary);
}

.lesson-detail-header-inner {
  align-items: flex-start;
  width: 100%;
  max-width: 600px;
}

.lesson-detail-header-inner button,
.lesson-detail-header-inner span {
  width: 36px;
  height: 36px;
}

.lesson-detail-header .retake-lesson-link {
  justify-content: center;
  margin-top: 10px;
  color: var(--gray-tertiary);
}

.lesson-detail-header .retake-lesson-link:hover {
  text-decoration: underline;
}

.lesson-detail-header .retake-lesson-link .vector-icon {
  height: 15px;
}

.lesson-detail-body {
  width: 100%;
  max-width: 500px;
}

.lesson-detail-container {
  width: 100%;
  margin: 30px 0;
  text-align: center;
}

.lesson-detail-score-container h4 {
  font-family: 'Outfit', sans-serif;
  font-weight: 400;
}

.lesson-detail-score-container h4 strong {
  font-weight: 500;
}

.lesson-detail-content {
  margin-top: 15px;
  padding: 30px;
  border: 1px solid #A7A7A7;
  border-radius: 5px;
}

.score-block-container {

}

.score-block-container .score-block-row {
  margin-bottom: 8px;
}

.score-block-container .score-block-row:last-of-type {
  margin-bottom: initial;
}


/* Responses */
.lesson-detail-content-response-score-wrapper {
  justify-content: flex-end;
}

.lesson-detail-content-response-score-container {
  width: 100%;
  /* max-width: 350px; */
}

.lesson-detail-content .message-container {
  margin-bottom: 15px;
}


/* Comments */
.lesson-detail-content-response-comments-container {
  align-items: flex-end;
  width: 100%;
  margin-top: 30px;
}

.lesson-detail-content-response-comment {
  width: 90%;
  padding: 5px 15px;
  margin-top: 5px;
  text-align: right;
  border-radius: 10px;
  border: 1px solid var(--gray-light);
  background-color: var(--gray-extra-light);
}

.lesson-detail-content-response-comment small {
  color: var(--gray-tertiary);
  font-style: italic;
}






/* ------------------------------------------------------------ */
/* Publically accessible profile & showcase */
/* ------------------------------------------------------------ */

.badge-showcase {
  margin: 60px;
  padding: 30px;
  border-radius: 10px;
  border: 1px solid var(--white-tertiary);
  background-color: var(--gray-background);
}

.badge-showcase h3 {
  margin-top: 10px;
  margin-bottom: 30px;
  text-align: center;
  line-height: 1.75rem;
}

.badge-showcase-image-container {
  min-height: 250px;
}

.badge-showcase img {
  max-width: 300px;
}

.badge-showcase .stats-certificate-container {
  padding: 30px 45px;
  max-width: 450px;
  background-color: var(--white-primary);
}

.badge-showcase .stats-certificate-container .issue-date-container {
  display: none;
}


.about-flow-speak-wrapper {
  margin-top: 90px;
  border-top: 1px solid var(--white-tertiary);
}

.about-flow-speak {
  max-width: 500px;
  padding: 30px;
  text-align: center;
  font-size: .9rem;
}

.showcase-not-found {
  margin-top: 45px;
  text-align: center;
}

.showcase-not-found h1 {
  margin-bottom: 10px;
  color: var(--gray-secondary);
}

.showcase-not-found a {
  color: var(--gray-secondary);
  text-decoration: underline;
}

.showcase-not-found img {
  margin-top: 30px;
  width: 80%;
  max-width: 200px;
}




@media screen and (max-width: 600px) {

  .badge-showcase .stats-certificate-container {
    padding: 30px;
  }

}

@media screen and (max-width: 500px) {

  .badge-showcase {
    margin: 30px;
  }

  .badge-showcase .stats-certificate-container {
    padding: 0px;
  }

}

@media screen and (max-width: 350px) {

  .badge-showcase {
    margin: 15px;
  }

  .badge-showcase img {
    max-width: 80%;
  }

}