
.main-body-content.microphone-access h3 {
  margin-bottom: 30px;
}

.mic-access-instruction {
  margin-bottom: 15px;
}

.mic-access-instruction h5 {
  font-weight: 500;
  padding: 5px 0;
  background-image: url('../images/icons/icon-chevron-down.png');
  background-size: 16px;
  background-position: center right;
  background-repeat: no-repeat;
  cursor: pointer;
  max-width: 500px;
}

.mic-access-instruction ol {
  margin-left: 30px;
  margin-bottom: 45px;
}
