
/* ------------------------------------------------------------ */
/* Complete tag */
/* ------------------------------------------------------------ */

.tag-complete {
  position: absolute;
  z-index: 10;
  /* right: 15px;
  top: 15px; */
  right: 30px;
  top: 30px;
  color: var(--gray-primary);
  background-color: var(--white-primary);
  font-size: .8rem;
  font-weight: 400;
  padding: 3px 15px;
  border-radius: 5px;
  opacity: .85;
}




/* ------------------------------------------------------------ */
/* Resuable parts */
/* ------------------------------------------------------------ */

.lesson-card {
  padding: 30px;
}

.lesson-card .title {
  font-size: 1.75rem;
  font-weight: 700;
  color: var(--white-primary);
  margin: 90px 0 10px 0;
}

.lesson-card .card-details-wrapper {
  color: var(--white-primary);
  font-size: .85rem;
}

/* .lesson-card .lesson-type { */
.lesson-card .lesson-card-series-wrapper {
  display: flex;
  justify-content: space-between;
  font-style: italic;
  font-size: .95rem;
}

.lesson-card.card-small .title {
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--white-primary);
  margin: 0px 0 10px 0;
}

.lesson-card.card-small .card-details-wrapper {
  display: flex;
  justify-content: space-between;
  font-size: .85rem;
}

.lesson-card .premium-label {
  display: flex;
  align-items: center;
  width: fit-content;
  font-size: .8rem;
  padding: 3px 8px 3px 3px;
  margin-bottom: 5px;
  border-radius: 5px;
  background-color: var(--white-primary);
  color: var(--gray-primary);
}


/* .lesson-card .premium-label-wrapper {} */

.lesson-card .premium-label .icon {
  margin-right: 3px;
  width: 20px;
  height: 20px;
}



/* ------------------------------------------------------------ */
/* Card Style 1 */
/* ------------------------------------------------------------ */


.lesson-card.style-1,
.series-lesson-highlight.style-1 {
  background: rgb(0,178,234);
  background: linear-gradient(40deg, rgba(160,2,186,1) 0%, rgba(0,178,234,1) 100%);
}





/* ------------------------------------------------------------ */
/* Card Style 2 */
/* ------------------------------------------------------------ */

.lesson-card.style-2,
.series-lesson-highlight.style-2 {
  background-color: var(--gray-primary);
}





/* ------------------------------------------------------------ */
/* Card Style 3 */
/* ------------------------------------------------------------ */

/* .lesson-card.style-3,
.series-lesson-highlight.style-3 {
  background: rgb(39,255,216);
  background: linear-gradient(40deg, rgba(208,255,221,1) 0%, rgba(39,255,216,1) 100%);
} */
.lesson-card.style-3,
.series-lesson-highlight.style-3 {
  background: rgb(39,255,216);
  background: linear-gradient(40deg, rgb(5, 90, 116) 0%, rgba(39,255,216,1) 100%);
}

.lesson-card.style-3 .title {
  /* color: var(--gray-primary); */
  margin: 0px 0 10px 0;
}

/* .lesson-card.style-3 .card-details-wrapper {
  color: var(--gray-tertiary);
} */

.lesson-card.style-3 .tag-complete {
  top: initial;
  bottom: 15px;
}

.lesson-card.style-3.card-small .tag-complete {
  top: 15px;
  bottom: initial;
}

.lesson-card.style-3 .tag-complete {
  right: 15px;

}



/* ------------------------------------------------------------ */
/* Card Style 4 */
/* ------------------------------------------------------------ */

.lesson-card.style-4,
.series-lesson-highlight.style-4 {
  padding: 15px;
  background: rgb(39,229,255);
  background: linear-gradient(40deg, rgb(228, 66, 220) 0%, rgba(39,229,255,1) 100%);
}

.lesson-card.style-4 .title {
  margin: 10px 0 10px 0;
}

.lesson-card.style-4 .card-details-wrapper {
  display: block;
  color: var(--white-primary);
}

.lesson-card.style-4 .image {
  height: 130px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 4px;
}

.lesson-card.style-4.card-small .title {
  margin: 10px 0 0px 0;
}

.lesson-card.style-4 .tag-complete {
  top: 15px;
  right: 15px;
}



/* ------------------------------------------------------------ */
/* Card Style 5 */
/* ------------------------------------------------------------ */

.lesson-card.style-5,
.series-lesson-highlight.style-5 {
  background: rgb(69,143,255);
  background: linear-gradient(40deg, rgba(69,143,255,1) 0%, rgba(39,255,216,1) 100%);
}

.lesson-card.style-5 .title {
  margin: 45px 0 10px 0;
}

.lesson-card.style-5.card-small .title {
  margin: 0px 0 10px 0;
}





/* ------------------------------------------------------------ */
/* Card Style 6 */
/* ------------------------------------------------------------ */

.lesson-card.style-6 {
  background-color: var(--white-primary);
  padding: initial;
}

.lesson-card.style-6 .title {
  margin: 10px 0 0px 0;
  color: var(--gray-primary);
  padding: 0 30px 30px;
  font-size: 1.25rem;
}

.lesson-card.style-6 .card-details-wrapper {
  display: block;
  color: var(--gray-primary);
  padding: 15px 30px 0 30px;
}

.lesson-card.style-6 .image {
  height: 200px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.lesson-card.style-6 .tag-complete {
  background-color: var(--white-primary);
  color: var(--gray-primary);
}

.lesson-card.style-6.card-small .tag-complete {
  color: var(--white-primary);
  background-color: var(--gray-primary);
}

.lesson-card.style-6 .premium-label {
  display: inline-flex;
  margin-top: 15px;
  margin-left: 15px;
}

.lesson-card.style-6 .tag-complete {
  top: 15px;
  right: 15px;
}



/* ------------------------------------------------------------ */
/* Card Style 7 */
/* ------------------------------------------------------------ */

.lesson-card.style-7 {
  background-color: var(--white-primary);
  padding: initial;
}

.lesson-card.style-7 .title {
  margin: 10px 0 0px 0;
  color: var(--gray-primary);
  padding: 0 30px 30px;
  font-size: 1.25rem;
}

.lesson-card.style-7 .card-details-wrapper {
  display: block;
  color: var(--gray-primary);
  padding: 30px 30px 0 30px;
}

.lesson-card.style-7 .image {
  height: 220px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.lesson-card.style-7 .premium-label {
  /* display: inline-flex;
  margin-top: 15px;
  margin-left: 15px; */
  position: absolute;
  bottom: 15px;
  left: 15px;
}

.lesson-card.style-7 .tag-complete {
  /* top: 15px;
  right: 15px;
  color: var(--white-primary);
  background-color: var(--gray-primary); */

  bottom: 15px;
  right: 15px;
  top: auto;
}




/* ------------------------------------------------------------ */
/* Card Style 8 */
/* ------------------------------------------------------------ */

.lesson-card.style-8,
.series-lesson-highlight.style-8 {
  background: rgb(0,178,234);
  background: linear-gradient(40deg, rgb(109, 2, 126) 0%, rrgb(44, 139, 218) 100%);
}

.lesson-card.style-8 p,
.lesson-card.style-8 a,
.lesson-card.style-8 strong {
  color: var(--white-primary);
}

.lesson-card.style-8 a {
  text-decoration: underline;
}

.lesson-card.style-8 .title {
  margin-top: 15px;
}

.lesson-card.style-8 .special-bottom {
  margin: 15px -30px -30px -30px;
  padding: 15px 30px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: var(--white-primary);
}

.lesson-card.style-8 .special-bottom p,
.lesson-card.style-8 .special-bottom p strong {
  font-size: .9rem;
  color: var(--gray-primary);
}





/* ------------------------------------------------------------ */
/* Bite */
/* ------------------------------------------------------------ */

.lesson-card.bite {
  position: relative;
}

.lesson-card.bite .tag-complete {
  z-index: 1001;
}

.lesson-card.bite .bite-answers-wrapper {
  margin-top: 120px;
}

/* .related-lesson-wrapper .lesson-card.bite { */
/* .lesson-card-outer-wrapper .lesson-card.bite {
  max-height: 250px;
  overflow: hidden;
} */

.lesson-card-outer-wrapper .lesson-card.bite .title {
  margin-top: 120px;
}

.lesson-card-outer-wrapper .lesson-card.bite .title.secondary-line {
  margin-top: 15px;
}

/* .lesson-card-outer-wrapper .lesson-card.bite .secondary-line { */
.lesson-card-outer-wrapper .lesson-card.bite .card-details-wrapper {
  /* margin-bottom: 150px; */
  margin: 30px -30px -30px -30px;
  padding: 15px 30px;
  background-color: var(--white-primary);
  color: var(--gray-primary);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.lesson-card-outer-wrapper .lesson-card.bite .bite-top-fade {
  height: 100%;
}

.related-lesson-wrapper .lesson-card.bite {
  padding: 0px;
}
