
/* ------------------------------------------------------------ */
/* Header */
/* ------------------------------------------------------------ */

.booster-header-wrapper {
  padding: 30px;
  border-bottom: 1px solid var(--white-tertiary);
}

.booster-progress-wrapper {
  width: 100%;
  max-width: 300px;
  margin: 0 15px;
}

.booster-progress-container {
  margin-top: 15px;
  gap: 5px;
}

.booster-progress-container span {
  flex-grow: 1;
  height: 8px;
  max-width: 30px;
  border-radius: 5px;
}

.booster-progress-container span.active {
  background-color: var(--active);
}

.booster-progress-container span.spacer {
  max-width: 5px;
}

.booster-progress-container span.inactive {
  background-color: var(--active);
  opacity: .5;
}






/* ------------------------------------------------------------ */
/* Body */
/* ------------------------------------------------------------ */

.lesson-body.booster .body-content {
  padding: 20px 30px 30px 30px;
  height: auto;
  overflow-y: initial;
}

.booster-avatar-big {
  width: 82px;
  min-width: 82px;
  height: 82px;
  margin-right: 15px;
}

.booster-avatar-medium {
  width: 64px;
  min-width: 64px;
  height: 64px;
  margin-right: 15px;
  border-radius: 64px;
}

.booster-avatar-audio-container {
  position: absolute;
  bottom: 4px;
  right: 18px;
  z-index: 100;
  width: 21px;
  height: 21px;
}

.lesson-body.booster .lesson-right-column {
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("https://files.flowspeak.io/files/lesson-images/booster-background-short.png");
}

.lesson-body.booster section.lesson-content {
  background-color: transparent;
}





/* ------------------------------------------------------------ */
/* Definitions */
/* ------------------------------------------------------------ */

.booster-definitions-wrapper {
  flex-grow: 1;
  padding: 30px;
}

.booster-definitions-container {
  padding: 30px;
  margin-top: 30px;
  margin-bottom: 60px;
  border-radius: 10px;
  background-color: var(--gray-light);
}

.booster-definition-container {
  margin-bottom: 30px;
}

.booster-definition-container:last-of-type {
  margin-bottom: initial;
}

p.booster-definition {
  color: var(--gray-tertiary);
}



/* ------------------------------------------------------------ */
/* Practice */
/* ------------------------------------------------------------ */

.booster-practice-container {
  height: 100%;
}

.booster-practice-teacher-container {
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  padding: 30px;
  margin-bottom: 45px;
}

.booster-example-text-container {
  padding: 15px;
  margin-top: 15px;
  border-radius: 10px;
  background-color: var(--gray-light);
}

.booster-example-text-container .transcript-words-score-container {
  justify-content: flex-start;
}

.booster-practice-speaker-container {
  flex-grow: 1;
  width: 100%;
  /* padding: 30px; */
  border-top: 1px solid var(--white-tertiary);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://files.flowspeak.io/files/lesson-images/booster-background-tall.png");
}


.booster-try-again-button {
  font-size: .9rem;
  opacity: .85;
}

.booster-try-again-button:hover {
  text-decoration: underline;
}

.booster-practice-learner-container {
  justify-content: space-between;
  height: 100%;
  padding: 30px;
}

.booster-practice-learner-container .booster-example-text-container {
  min-width: 170px;
  border: 1px solid var(--white-tertiary);
  background-color: var(--gray-extra-light);
}

.booster-practice-learner-container .response-score {
  pointer-events: none;
}

.booster-practice-learner-container .response-score span {
  font-size: .9rem;
}


/* ------------------------------------------------------------ */
/* Translate */
/* ------------------------------------------------------------ */

span.booster-translate-phrase {
  margin-left: 15px;
  color: var(--gray-tertiary);
  font-size: .9rem;
  line-height: 1.2rem;
}

.booster-translate-definition {
  /* margin: 5px 15px; */
  margin-top: 5px;
  padding: 5px 10px;
  font-size: .9rem;
  line-height: 1.2rem;
  border: 1px solid var(--gray-inactive);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top: none;
  background: var(--gray-extra-light);
  background: linear-gradient(0deg, rgba(253,253,253,1) 0%, rgba(253,253,253,0) 100%);
  opacity: .6;
}





/* ------------------------------------------------------------ */
/* Talk */
/* ------------------------------------------------------------ */

.booster-talk-header {
  padding: 15px 30px;
  border-bottom: 1px solid var(--white-tertiary);
  background-color: var(--gray-light);
}

.booster-talk-header .flex-row:first-of-type {
  align-items: flex-start;
}

p.booster-talk-reminder {
  margin-top: 15px;
  font-size: .9rem;
  font-style: italic;
}

.window-modal.booster-phrase-modal p {
  margin-bottom: initial;
}




/* ------------------------------------------------------------ */
/* Responsive */
/* ------------------------------------------------------------ */


@media screen and (max-height: 845px) {

  .booster-definitions-wrapper p {
    font-size: .9rem;
    line-height: 1.2rem;
  }

  .booster-avatar-big {
    width: 65px;
    min-width: 65px;
    height: 65px;
  }

  .booster-avatar-medium {
    width: 54px;
    min-width: 54px;
    height: 54px;
  }

  .booster-practice-teacher-container {
    margin-bottom: 15px;
  }

  .booster-practice-teacher-container,
  .booster-practice-learner-container .transcript-words-score-container,
  .booster-practice-learner-container span .clean-button {
    font-size: .9rem;
    line-height: 1.3rem;
  }

  .booster-talk-header {
    padding: 15px;
    font-size: .9rem;
  }

  .lesson-body.booster .body-content {
    padding: 15px;
  }

}

@media screen and (max-height: 700px) {

  h3 {
    line-height: 1.3rem;
  }

  .booster-header-wrapper {
    padding-bottom: 15px;
  }

  .booster-definitions-wrapper {
    margin-top: -15px;
  }

  .booster-definitions-container {
    margin-top: 15px;
    margin-bottom: initial;
    padding: 15px;
  }

  .booster-avatar-big {
    width: 55px;
    min-width: 55px;
    height: 55px;
  }

  .booster-practice-teacher-container,
  .booster-practice-learner-container {
    padding: 15px;
  }

}