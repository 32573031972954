
.lesson-body.bite.listening {

}


.listener-wrapper {
  margin: 15px 0;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.9);
}

.listener-audio-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 30px;
}

.listener-playback-speed {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 50px;
}

.playback-speed {
  width: fit-content;
  padding: 2px 5px;
  margin-right: 15px;
  border-radius: 5px;
  font-size: .8rem;
  color: var(--white-primary);
  background-color: var(--gray-tertiary);
  cursor: pointer;
}

.playback-speed.selected {
  background-color: var(--gray-primary);
}

.listener-audio-wrapper .icon-audio {
  width: 65px;
  height: 65px;
  background-image: url('../images/icons/icon-mic-enclosed.png');
}

.listener-text {
  /* margin-bottom: 15px; */
  margin: 0 15px;
}

/* .listener-text.obscure {
  -webkit-filter: blur(5px);
  -moz-filter: blur(5px);
  -o-filter: blur(5px);
  -ms-filter: blur(5px);
  filter: blur(5px);
} */

.lesson-body.bite.listening .secondary-line p {
  /* font-size: 1.25rem;
  text-align: center; */
  /* padding: 15px;
  border-radius: 5px;
  background-color: rgba(39, 18, 42, 0.85); */
}


.text-toggle-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 15px;
}

.toggle-text {
  font-size: .9rem;
  font-style: italic;
  padding: 10px 30px;
  cursor: pointer;
}

.listener-footer {
  background-color: var(--gray-primary);
  color: var(--white-primary);
  padding: 10px 15px;
  text-align: center;
  font-size: 1.25rem;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.listener-footer.small {
  font-size: 1.125rem;
}

.listener-footer.smaller {
  font-size: 1rem;
}

.lesson-body.bite.listening .bite-action-wrapper {
  margin-bottom: 120px;
}

@media screen and (min-width: 800px) {

  .lesson-body.bite.listening .bite-action-wrapper {
    margin-bottom: initial;
  }

}