

.community-responses {
  width: 100%;
  margin-top: 5px;
  /* margin-bottom: 15px; */
  font-style: italic;
  font-size: .9rem;
}

.link-to-responses,
.link-to-comments {
  width: 45px;
  min-width: 45px;
  height: 16px;
  opacity: .75;
}

/* .link-to-responses {
  margin-left: 15px;
} */

/* .link-to-comments {
  margin-right: 15px;
} */

.link-to-responses:hover,
.link-to-comments:hover {
  opacity: 1;
}

.community-response {
  /* display: flex;
  align-items: center; */
}

.question-wrapper.community-index .community-responses {
  padding-left: 10px;
}

.response-wrapper.community-response {
  /* margin-left: 30px; */
}

.response-wrapper.community-response:last-of-type {
  margin-bottom: 15px;
}

.response-wrapper.community-response .extra-response-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  /* margin-right: 15px; */
}

.response-wrapper.community-response .community-responses {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 30px;
}

.response-wrapper.community-response .card-content {
  width: 100%;
}

.response-wrapper.community-response.comment .card-content {
  background-color: var(--gray-light);
  color: var(--gray-primary);
}

.response-wrapper.community-response .play-audio-icon {
  transform: rotate(180deg);
}

.community-response .card {
  flex-grow: 1;
  box-shadow: initial;
  padding: 15px;
  margin-top: 15px;
  margin-left: 45px;
  border: 1px solid var(--light-gray);
  background-color: var(--extra-light-gray);
}

.community-response .card .response-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid var(--light-gray);
  padding-top: 10px;
  margin-top: 10px;
}

.community-response .card .response-by {
  font-style: italic;
  flex-grow: 1;
  text-align: right;
  padding-right: 15px;
}

.community-responses .author {
  padding-left: 10px;
}

.community-responses .comment-count {
  padding-left: 10px;
  padding-right: 10px;
  white-space: nowrap;
}

.community-responses .response-created-at {
  color: var(--gray-tertiary);
  font-size: .8rem;
  font-style: italic;
}

/* .community-response .card .response-audio,
.community-response .card .comment-count {
  width: 32px;
  height: 32px;
}

.community-response .card .comment-count {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .8rem;
  color: #fff;
} */

.community-response .card .response-audio {
  justify-self: flex-start;
}

.community-response .card .response-audio .media-right {
  margin-left: 0px;
  height: 100%;
  width: 100%;
}

.community-response .card .response-audio .media-right div {
  /* display: flex;
  align-content: center;
  justify-content: center; */
}

.community-response .card .response-audio .media-right figure {
  margin-top: 4px;
  transform: rotate(180deg);
}

.community-response.comment-index {
  margin-bottom: 30px;
}

.community-response.comment {
  /* margin-left: 30px; */
}

.community-comment-form {
  border-top: 1px solid var(--gray-tertiary);
  padding: 30px;
}

.community-comment-form .label {
  color: var(--primary-teal);
  font-weight: initial;
}

.community-comment-form textarea {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  background-color: var(--gray-light);
}

.community-comment-form .submit-button {
  display: flex;
  justify-content: flex-end;
}

.community-comment-form .submit-button .button {
  background-color: var(--active);
  border: initial;
  color: var(--white-primary);
}

.community-comment-form .submit-button .button:hover {
  box-shadow: 0 8px 10px -6px rgb(0,0,0, .2);
}

.conversation-wrapper .response-header {
  text-align: center;
  font-style: italic;
  font-weight: 600;
  margin-bottom: 30px;
}

.response-details .icon-flag {
  margin-top: 4px;
  cursor: pointer;
  opacity: .6;
}

.response-details .icon-flag:hover {
  opacity: 1;
}