
.record-wrapper {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  height: 130px;
  border-top: 1px solid var(--gray-tertiary);

  user-select:none;
  -moz-user-select:none;
  -webkit-user-select:none;
  -webkit-touch-callout:none;
  -ms-user-select:none;
}

.record-instructions-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  width: 100%;
}

.record-wrapper.no-clickthrough {
  pointer-events: none;
}

.record-wrapper.listening {

}

.record-audio {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 5px;
  cursor: pointer;
}

#record-audio-mobile {
  display: none;
}

.record-audio-icon-wrapper {
  /* position: absolute; */
  display: flex;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 75px;
  background-color: var(--active);
  border-radius: 75px;
}

.record-audio-icon-wrapper.audio-active {
  opacity: .2;
}

.record-audio-icon-wrapper:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 8px;
}

.record-audio-icon {
  width: 75px;
  height: 75px;
  background-image: url('../images/icons/icon-microphone.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.record-audio.inactive {
  opacity: .5;
  pointer-events: none;
}

.hide-icon {
  display: none;
}


@media screen and (min-width: 475px) {

  .record-wrapper {
    display: flex;
    flex-direction: column;
  }

}


/* ------------------------------------------------------------ */
/* Microphone Permissions Overlay */
/* ------------------------------------------------------------ */

.record-component-wrapper {
  position: relative;
}

.record-component-wrapper.denied,
.record-component-wrapper.prompt,
.record-component-wrapper.pending {

}

.record-permissions-message {
  position: absolute;
  z-index: 1000;
  bottom: 0px;
  width: 100%;
  height: 129px;
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, .9);
}

.record-permissions-message p {
  width: 50%;
  text-align: center;
}

@media screen and (max-width: 400px) {
  .record-permissions-message p {
    width: 85%;
  }
}




/* ------------------------------------------------------------ */
/* Record Next Button */
/* ------------------------------------------------------------ */


.record-next-button {
  position: absolute;
  z-index: 1000;
  right: 60px;
  top: 35%;
  width: 45px;
  height: 45px;
  border: 1px solid var(--primary-green-active);;
  border-radius: 45px;
  text-align: center;
  cursor: pointer;
  background-image: url('../images/icon-right-chevron-dark.png');
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.record-next-button:hover {
  background-image: url('../images/icon-right-chevron-white.png');
  background-color: var(--primary-green);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 8px;
}

.record-next-button-wrapper.disable {
  opacity: .5;
  pointer-events: none;
}

.record-action-done {
  height: 100%;
  text-align: center;
  padding: 15px;
}

.record-action-done p {
  padding: 10px;
  font-weight: 500;
}

.record-action-done p:first-child {
  font-weight: 600;
  font-size: 1.4rem;
  color: var(--primary-green);
}

.record-action-done a:first-child {
  color: var(--dark-green);
  margin-right: 8px;
}

.record-action-done a:last-child {
  color: var(--primary-green);
  margin-left: 8px;
}

.record-action-done a:hover {
  text-decoration: underline;
}

.record-instructions {
  font-size: .8rem;
  color: #7c7c7c;
  text-align: center;
  /* position: absolute;
  left: 0;
  right: 0;
  top: 5px;
  margin-left: auto;
  margin-right: auto;
  width: 200px; */
}

.record-instructions.inactive {
  color: var(--white-primary);
}


@media (hover: none) {

  .record-wrapper {
    height: 96px;
  }

  .record-audio {
    padding-top: 0px;
    width: 40%;
    min-width: 40%;
  }

  #record-audio-web {
    display: none;
  }

  #record-audio-mobile {
    display: flex;
    align-items: center;
  }

  .conversation-body {
    padding: 0 30px;
  }

  .record-instructions-wrapper {
    justify-content: flex-end;
  }

}


/* ------------------------------------------------------------ */
/* Listening Spinner */
/* ------------------------------------------------------------ */

.listening-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  /* margin: 24px 0 0 24px; */
  margin-left: 15px;
  text-align: center;
  font-size: 10px;
}

.listening-spinner.hide-icon {
  display: none;
}

.listening-spinner > div {
  background-color: var(--white-primary);
  height: 100%;
  width: 2px;
  display: inline-block;
  margin-right: 1px;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.listening-spinner .listen2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.listening-spinner .listen3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.listening-spinner .listen4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.listening-spinner .listen5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% {
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}

/* End Listening Spinner Component */




.response-wrapper.active .card-content {
  padding: initial
}

/* reset button styles */
button.record-response-button {
  background-color: initial;
  border: initial;
  color: unset;
  margin: unset;
  width: 100%;
  font-size: 1rem;
}

.record-response-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 20px;
  border-radius: 20px;
  cursor: pointer;
}

.record-response-button.disabled {
  pointer-events: none;
}

#record-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-left: 15px;

  background-image: url('../images/icons/icon-microphone-minimal.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}




/* ------------------------------------------------------------ */
/* Post Record Button */
/* ------------------------------------------------------------ */

.post-record-wrapper {
  display: flex;
  align-items: center;
}

.post-record-audio-button {
  /* width: 100%; */
  display: flex;
  align-items: center;
  cursor: pointer;
}

.post-record-audio-button #record-icon {
  width: 30px;
  height: 30px;
  margin-left: 0px;
  margin-right: 10px;
  /* background-color: var(--active); */
  /* border-radius: 40px; */
  background-image: url('../images/icons/icon-microphone-dark.png');
}

.post-record-audio-button #record-icon.dark {
  background-image: url('../images/icons/icon-microphone-dark.png');
}

.post-record-audio-button .listening-spinner {
  width: 30px;
  height: 30px;
  margin-left: 0px;
  margin-right: 10px;
  background-color: var(--active);
  border-radius: 40px;
}

.post-record-audio-button .icon-audio {
  margin-right: 10px;
}

.post-record-wrapper .remove-audio {
  margin-left: 10px;
  columns: var(--gray-tertiary);
  cursor: pointer;
  font-style: italic;
  font-size: .9rem;
}


