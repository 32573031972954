


.main-body-content.challenge {
  padding: 0px;
}




/* ==================================== */
/* Header */
/* ==================================== */


.challenge-header-wrapper {
  position: fixed;
  z-index: 10;
  width: 100%;
  font-family: 'Outfit', sans-serif;
}

.challenge-header-background {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 160px;
  background: rgb(118,2,118);
  background: linear-gradient(62deg, rgba(118,2,118,1) 0%, rgba(221,1,141,1) 100%);
}

.challenge-header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 800px;
  padding: 15px;
  text-align: center;
  color: var(--white-primary);
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('../images/challenge/challenge-flourish.png');
}

.challenge-header-link {
  align-self: stretch;
  width: 100px;
  padding-top: 30px;
  text-align: right;
}

.challenge-header-link a {
  padding: 15px;
}

.challenge-header-link a .icon {
  width: 16px;
  height: 16px;
}

.challenge-header-container h1 {
  color: var(--white-primary);
}

.challenge-header-brand {
  font-weight: 600;
  font-size: 1.4rem;
  margin-bottom: -5px;
}

.challenge-header-detail {
  margin-top: -5px;
  opacity: .85;
  font-size: 1.3rem;
}

.challenge-header-countdown {
  /* padding: 10px 30px; */
  /* border-bottom: 1px solid #9D017F; */
  margin-bottom: 30px;
  font-size: 1.2rem;
  font-weight: 300;
  text-align: center;
  /* background-color: var(--white-primary); */
}

.challenge-header-countdown strong {
  font-size: 1.3rem;
  font-weight: 500;
}

.main-body.challenge {
  padding-top: 200px;
  max-width: 750px;
  padding-bottom: 90px;
}


@media screen and (max-width: 700px) {

  .challenge-header-link {
    display: none;
  }

}


@media screen and (max-width: 360px) {

  .challenge-header-wrapper .challenge-header-brand {
    font-size: 1.25rem;
  }

  .challenge-header-wrapper h1 {
    font-size: 1.85rem;
  }

}



/* ==================================== */
/* Section Complete */
/* ==================================== */

.challenge-complete-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  margin-top: 30px;
  margin-bottom: 60px;
  border: 2px solid #E2CC00;
  border-radius: 5px;
  background-color: #EBEAE6;
}

.challenge-complete-heading {
  font-family: 'Outfit', sans-serif;
  text-align: center;
  padding: 15px 45px;
  margin-top: -75px;
  margin-bottom: 30px;
  border: 2px solid #E2CC00;
  border-radius: 5px;
  background-color: #F0EEDF;
}

.challenge-end-date {
  display: block;
  font-size: 1rem;
  font-weight: 300;
  font-style: italic;
}



/* ==================================== */
/* Section Keep Going */
/* ==================================== */

.challenge-keep-going-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
}

.challenge-keep-going-container h3 {
  margin-bottom: 15px;
}




/* ==================================== */
/* Challenge Card */
/* ==================================== */

.challenge-cards-container {
  width: 100%;
  max-width: 500px;
}

.challenge-card-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 15px;
  margin-bottom: 10px;
  color: var(--gray-primary);
  border-radius: 5px;
  background-color: var(--white-primary);
}

.challenge-card-container.is-learner {
  border: 3px solid var(--active);
  background-color: #fdf9ff;
}

.challenge-card-details {
  margin-left: 15px;
  flex: 1;
}

.challenge-card-name {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.challenge-card-name p {
  font-size: 1.1rem;
  font-weight: 500;
  margin-right: 15px;
}

.challenge-card-score {
  font-size: .9rem;
  color: var(--gray-tertiary);
}

.challenge-card-bar-container {
  max-width: 120px;
  margin-top: 7px;
  margin-bottom: 2px;
}

.challenge-card-completed-count {
  font-size: .8rem;
  color: var(--gray-tertiary);
}

.challenge-card-icon-container {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}

.challenge-card-icon-container .icon {
  width: 50px;
  height: 50px;
}





/* ==================================== */
/* Messages */
/* ==================================== */

.challenge-not-in-list-message {
  /* margin-bottom: 60px; */
  margin-bottom: 30px;
  text-align: center;
}


.challenge-upgrade-message {
  display: block;
  margin-bottom: 60px;
  text-align: center;
  font-family: 'Outfit', sans-serif;
}

.challenge-upgrade-message p {
  font-size: 1.3rem;
  font-weight: 300;
  color: var(--gray-primary);
}

.challenge-upgrade-message p strong {
  color: var(--active);
  font-weight: 500;
}





/* ==================================== */
/* Public Profile */
/* ==================================== */

.challenge-public-profile-icon {
  position: relative;
}

.challenge-public-profile-icon .icon {
  width: 60px;
  height: 60px;
  margin-right: 15px;
}

.challenge-public-profile-icon span {
  position: absolute;
  z-index: 10;
  top: 15px;
  left: 15px;
  width: 30px;
  font-size: 1.4rem;
  font-weight: 500;
  font-family: 'Outfit', sans-serif;
  color: var(--white-primary);
  text-align: center;
}






/* ==================================== */
/* Dashboard Challenge Card */
/* ==================================== */

.dashboard-challenge-card-wrapper {
  margin-top: -15px;
  margin-bottom: 30px;
}

.dashboard-challenge-card-container {
  justify-content: center;
  width: 100%;
  max-width: 700px;
  padding: 15px;
  border: 1px solid var(--white-tertiary);
  border-radius: 5px;
  background-color: var(--white-primary);
}

.dashboard-challenge-card-container .icon {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}

.dashboard-challenge-card-container strong {
  display: block;
  margin-bottom: -3px;
  font-family: 'Outfit', sans-serif;
  color: var(--gray-primary);
}

.dashboard-challenge-card-container span {
  color: var(--gray-tertiary);
}


/* @media screen and (max-width: 700px) {

  .dashboard-challenge-card-container {
    width: 120%;
  }
} */


@media screen and (max-width: 380px) and (max-height: 730px) {

  .header .title-wrapper h1 {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }

  .header .title-wrapper p {
    font-size: 1rem;
  }

  .dashboard-challenge-card-wrapper {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .dashboard-challenge-card-container {
    padding: 5px 15px;
  }

}


/* ==================================== */
/* Icons */
/* ==================================== */

/* --- Full --- */
.icon-challenge-full-burgendy {
  background-image: url('../images/challenge/icon-challenge-full-burgendy.png');
}

.icon-challenge-full-gray {
  background-image: url('../images/challenge/icon-challenge-full-gray.png');
}


/* --- Complete --- */
.icon-challenge-full-burgendy-complete {
  background-image: url('../images/challenge/icon-challenge-full-burgendy-complete.png');
}

.icon-challenge-full-gray-complete {
  background-image: url('../images/challenge/icon-challenge-full-gray-complete.png');
}

.icon-challenge-outline-gray-complete {
  background-image: url('../images/challenge/icon-challenge-outline-gray-complete.png');
}



/* --- Complete with shadow --- */
.icon-challenge-full-burgendy-complete-with-shadow {
  background-image: url('../images/challenge/icon-challenge-full-burgendy-complete-with-shadow.png');
}
.icon-challenge-full-gray-complete-with-shadow {
  background-image: url('../images/challenge/icon-challenge-full-gray-complete-with-shadow.png');
  opacity: .7;
}

/* --- Outline --- */
.icon-challenge-outline-burgendy {
  background-image: url('../images/challenge/icon-challenge-outline-burgendy.png');
}

.icon-challenge-outline-gray {
  background-image: url('../images/challenge/icon-challenge-outline-gray.png');
}

/* --- Outline half full --- */
.icon-challenge-outline-half-full-burgendy {
  background-image: url('../images/challenge/icon-challenge-outline-half-full-burgendy.png');
}

.icon-challenge-outline-half-full-gray {
  background-image: url('../images/challenge/icon-challenge-outline-half-full-gray.png');
}