
/* ==================================== */
/* Layout */
/* ==================================== */

.lesson-body.aitalk .lesson-right-column {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.aitalk-header-wrapper {
  flex-shrink: 0;
  border-bottom: 1px solid var(--white-tertiary);
}

.lesson-body.aitalk .body-content {
  padding: 20px 30px 30px 30px;
  height: auto;
  overflow-y: initial;
}


@media screen and (max-width: 700px) {

  .lesson-body.aitalk .body-content {
    padding: 15px;
  }

}

@media screen and (max-width: 700px) {

  .lesson-body.aitalk .body-content {
    margin-top: initial;
  }

}



/* ==================================== */
/* Header */
/* ==================================== */

.aitalk-header-container {

}

.aitalk-header-image {
  width: 100%;
  height: 100%;

  background-position: center;
  background-repeat: no-repeat;
  background-size: 150%;
}

.aitalk-header-blur {
  width: 100%;
  height: 100%;
  padding: 30px;
  padding-bottom: 0px;
  backdrop-filter: blur(25px);
  background-image: linear-gradient(rgba(23,2,23,.85), rgba(23,2,23,0));
}

.aitalk-header-container p {
  font-size: .9rem;
  color: var(--white-primary);
}

.aitalk-header-container h3 {
  text-align: center;
  padding: 0 10px;
  color: var(--white-primary);
}

.aitalk-header-description-container {
  position: relative;
  height: 74px;
  margin-top: 15px;
}

.aitalk-header-image-background {
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 2px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: blueviolet;
}

.aitalk-header-description-container img {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 74px;
  height: 74px;
}

.aitalk-header-description {
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  left: 40px;
  width: calc(100% - 45px);
  padding: 8px 20px 8px 40px;
  font-size: .9rem;
  line-height: 1.2rem;
  border-radius: 30px;
  border: 1px solid #3C2034;
  background-color: var(--white-primary);
}

.aitalk-header-divider {
  width: calc(100% + 60px);
  height: 37px;
  margin: -37px -30px 0px -30px;

  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  background-color: var(--white-primary);
}

.aitalk-help-container {
  padding: 5px 0 15px;
  border-bottom: 1px solid var(--white-tertiary);
}

.aitalk-help-container button {
  font-size: .9rem;
  color: var(--gray-tertiary);
  cursor: pointer;
}



/* ==================================== */
/* Body */
/* ==================================== */

.aitalk-done-button-container {
  padding-top: 60px;
  min-height: 100px;
}

.aitalk-done-button-container button,
.aitalk-done-button-container button.button[disabled] {
  font-size: 1.25rem;
  padding: 15px 30px;
  font-family: 'Outfit', sans-serif;
  color: var(--white-primary);
  border: none;
  background-color: var(--active);
}

.aitalk-done-button-container button:hover {
  color: var(--white-primary);
  box-shadow: 0 6px 15px -4px rgb(0,0,0, .5);
}

.aitalk-done-button-container button:active,
.aitalk-done-button-container button:focus {
  color: var(--white-primary);
  opacity: .75;
}



/* ------------------------------------------------------------ */
/* Messages */
/* ------------------------------------------------------------ */

.aitalk-message-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 15px 0;
}

.aitalk-message-container.learner {
  justify-content: flex-end;
  margin-left: 15px;
}

.aitalk-message-container.assistant {
  justify-content: flex-start;
  margin-right: 15px;
}


.aitalk-message {
  padding: 10px 20px;
  border-radius: 20px;
}

.aitalk-message-container.learner .aitalk-message {
  display: flex;
  flex-direction: row;
  margin-right: 15px;
  text-align: right;
  background-color: var(--gray-light);
}

.aitalk-message-container.learner .aitalk-message p {
  /* margin-left: 15px; */
  margin-left: 5px;
}

.aitalk-message-container.assistant .aitalk-message {
  margin-left: 10px;
  background-color: var(--information);
}

.aitalk-message-container.learner .avatar-image {
  flex-shrink: 0;
}

.ai-tone-tip {
  margin-bottom: 30px;
  font-size: .9rem;
  font-style: italic;
  color: var(--gray-secondary);
  text-align: center;
}


@media screen and (min-width: 1100px) {

  .aitalk-message-container.learner {
    margin-left: 115px;
  }

  .aitalk-message-container.assistant {
    margin-right: 115px;
  }

}





/* ==================================== */
/* Modals */
/* ==================================== */

.window-modal.aitalk-message-modal {
  padding: 0px;
  min-height: 250px;
}

.window-modal.aitalk-message-modal .window-modal-header {
  padding: 30px 30px 0 30px;
}

.window-modal.aitalk-message-modal .modal-title {
  font-size: 1rem;
  font-weight: normal;
}

.window-modal.aitalk-message-modal .window-modal-subtitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -15px;
  padding-bottom: 15px;
  font-size: 2.5rem;
  font-family: 'Outfit', sans-serif;
  font-weight: 500;
  border-bottom: 1px solid var(--white-tertiary);
}

.window-modal.aitalk-message-modal .window-modal-body {
  padding: 0px 30px 30px 30px;
}

.window-modal.aitalk-message-modal p {
  margin-bottom: initial;
}

.window-modal.aitalk-message-modal .how-to-improve {
  text-align: center;
  color: var(--gray-tertiary);
}

.window-modal.aitalk-message-modal ul,
.window-modal.aitalk-message-modal ol {
  list-style-type: disc;
  margin-left: 15px;
  margin-bottom: 15px;
}

.ai-talk-scores-wrapper {
  position: relative;
  padding-bottom: 15px;
}

.ai-talk-scores-inner.no-access {
  filter: blur(3px);
  -webkit-filter: blur(2px);
}

.ai-talk-scores-wrapper .word-detail-premium-cta {
  height: 100%;
}

.window-modal.aitalk-message-modal .ai-talk-score-row {
  margin-bottom: 30px;
}

.window-modal.aitalk-message-modal .ai-talk-score-row:last-of-type {
  margin-bottom: initial;
}

.window-modal.aitalk-message-modal .ai-talk-score-row-header {
  margin-bottom: 5px;
}

.window-modal.aitalk-message-modal .ai-talk-score-spacer {
  margin: 0 -30px 30px -30px;
  border-top: 1px solid var(--white-tertiary);
}



/* ------------------------------------------------------------ */
/* Misc */
/* ------------------------------------------------------------ */

.aitalk-get-example-button {
  margin-top: 3px;
  font-size: .9rem;
  color: var(--gray-primary);
  cursor: pointer;
  text-decoration: underline;
  opacity: .65;
}

.aitalk-get-example-button:hover {
  opacity: 1;
}


.aitalk-score-button-container {
  justify-content: flex-end;
  margin: -15px 80px 0 0;
}

.aitalk-score-button {
  font-size: .9rem;
  /* color: #9701BC; */
  cursor: pointer;
}

.aitalk-score-button small {
  color: var(--gray-tertiary);
}

.aitalk-score-button .response-score span {
  cursor: pointer !important;
}


.ai-talk-translate-toggle-container {
  justify-content: flex-start;
}

.ai-talk-translate-toggle-container .profile-input-wrapper {
  display: flex;
  margin-bottom: initial;
  margin-right: 15px;
}

.ai-talk-translate-toggle-container .profile-input-wrapper label {
  margin-right: 15px;
}



/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
 /* https://codepen.io/nzbin/pen/GGrXbp */



 .dot-flashing {
  position: relative;
  width: 8px;
  height: 8px;
  margin: 0 5px;
  border-radius: 5px;
  background-color: #b0bfc4;
  color: #b0bfc4;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before, .dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -13px;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: #b0bfc4;
  color: #b0bfc4;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 13px;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: #b0bfc4;
  color: #b0bfc4;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #b0bfc4;
  }
  50%, 100% {
    background-color: rgba(152, 128, 255, 0.2);
  }
}