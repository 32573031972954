
/* --------------------------------- */
/* Header */
/* --------------------------------- */

.expression-header-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding: 30px;
}



/* --------------------------------- */
/* Progress Steps */
/* --------------------------------- */

.expression-progress-container {
  padding: 15px 30px;
}




/* --------------------------------- */
/* Body */
/* --------------------------------- */

.expression-body-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 600px;
}

.expression-body-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 30px;
  text-align: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../images/expression-background.jpg');
  background-color: #5C0F6F;
}

.expression-inner-content-container {
  flex-grow: 1;
  width: 100%;
  border-radius: 5px;
  background-color: var(--white-primary);
}

.expression-inner-content-container .image {
  margin-left: 15px;
}

.expression-inner-content-head {
  padding: 30px 30px 15px;
  border-bottom: 1px solid var(--white-tertiary);
}

.expression-simple-heading {
  margin-bottom: 10px;
  color: var(--gray-tertiary);
}

.expression-inner-content {
  padding: 15px 30px;
  margin-bottom: 45px;
  min-height: 165px;
}


.expression-record-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -58px;
  min-height: 115px;
}


.expression-record-icon-container-base {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 50px;
  background-color: #830371;
  border: 5px solid #830371;
}

.expression-record-icon-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 40px;
  background-color: var(--white-primary);
}

.expression-record-container #record-icon {
  width: 30px;
  height: 30px;
  margin-left: initial;
  background-image: url('../images/icons/icon-microphone-outline-purple.png');
}

.expression-record-container .record-response-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--white-primary);
}


.expression-record-container .listening-spinner {
  width: 55px;
  height: 45px;
  margin: 0px;
}

.expression-record-container .listening-spinner > div {
  width: 3px;
  background-color: #830371;
}


.expression-record-container .audio-processing-circle {
  width: 30px;
  height: 30px;
  margin-left: initial;
}

.expression-record-container .audio-processing-circle .sk-circle:before {
  background-color: #830371;
}



.expression-inner-content-container .lesson-no-access-message {
  margin: 0px;
  padding: 0px;
}



/* --------------------------------- */
/* Expression Score */
/* --------------------------------- */

.expression-score-container {
  display: flex;
  /* flex-direction: row; */
  flex-direction: column;
  /* align-items: stretch; */
  align-items: center;
  justify-content: center;
  /* margin: 15px 0 50px; */
  margin-top: 30px;
}

.expression-score-container p {
  color: var(--gray-tertiary);
  font-family: 'Outfit', sans-serif;
  font-size: 1.125rem;
}

.expression-score-container .score-donut-wrapper {
  display: block;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.expression-score-container .score-donut-wrapper .pie {
  --b: 5px;
  --w: 30px;
}

.expression-score-container .score-donut-wrapper .donut-hole {
  width: 30px;
  height: 30px;
  border-radius: 20px;
  border-width: 5px;
}






/* --------------------------------- */
/* Continue Button */
/* --------------------------------- */

.expression-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}




/* --------------------------------- */
/* Misc */
/* --------------------------------- */

.lesson-right-column .related-lesson-wrapper.expression {
  padding: 30px;
  margin-top: 15px;
}

.related-lesson-wrapper.expression .lesson-card-wrapper {
  width: 100%;
}

.expression-translate-toggle-container {
  margin-top: 30px;
}



/* --------------------------------- */
/* Responsive */
/* --------------------------------- */

@media screen and (max-height: 700px) {

  .expression-body-wrapper {
    min-height: 450px;
  }

  .expression-header-container {
    padding-bottom: 15px;
  }

  .screen-toggler-container button,
  .screen-toggler-container .faux-button {
    font-size: 1rem;
  }

  .expression-body-container {
    padding: 15px;
  }

  .expression-inner-content-head {
    padding: 15px;
  }

  .expression-inner-content {
    padding: 15px;
  }

  .expression-score-container {
    margin-top: 15px;
  }

}


.screen-toggler-wrapper {
  display: none;
}

/* @media screen and (min-width: 700px) {

  .screen-toggler-wrapper {
    display: none;
  }

} */
