
.series-wrapper {
  margin-bottom: 75px;
}

.series-info-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.series-description-wrapper {
  display: flex;
}

.series-intro-label {
  display: inline-block;
  font-size: .8rem;
  font-style: italic;
  padding: 2px 15px;
  margin-bottom: 5px;
  color: var(--white-primary);
  border-radius: 5px;
  background-color: var(--gray-primary);
}

.series-info h3 {
  font-size: 1.25rem;
}

.series-level {
  margin-top: 10px;
}

.series-badge {
  width: 30px;
  height: 30px;
  margin-right: 15px;
  background-color: tomato;
}

.series-goal-wrapper {
  justify-self: flex-end;
}


.series-lessons-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  gap: 20px;
}

.series-lesson-wrapper {
  width: 100%;
  color: var(--gray-primary);
}

.series-lesson-wrapper a {
  color: var(--gray-primary);
}

.series-lesson {
  width: 100%;
  background-color: var(--white-primary);
  border-radius: 5px;
  padding: 15px;
  /* box-shadow: 0 8px 9px -6px rgb(0,0,0, .4); */
  box-shadow: 0 5px 10px -2px rgb(0 0 0 / .4);
}

.series-lesson:hover {
  /* box-shadow: 0 8px 9px -6px rgb(0,0,0, .6); */
  box-shadow: 0 5px 10px -2px rgb(0 0 0 / .6);
}

.series-lesson-header {
  display: flex;
  justify-content: space-between;
}

.series-lesson-title {
  font-weight: 600;
}

.series-lesson-indicators {
  display: flex;
  align-items: center;
}

.series-lesson-indicators .premium-label {
  height: 24px;
  margin-right: 10px;
}

.series-lesson .icon.icon-complete {
  width: 16px;
  height: 16px;
  opacity: .75;
}

.series-lesson-info {
  display: flex;
  justify-content: space-between;
  color: var(--gray-tertiary);
  font-size: .85rem;
  padding: 5px 0;
}

.series-lesson-highlight {
  height: 10px;
  margin-top: -10px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  opacity: .75;
}

.subscription-needed {
  cursor: pointer;
  opacity: .75;
}


@media screen and (max-width: 700px) {

  /* Override grid b/c the grid isn't actually working on phones */
  .series-lessons-wrapper {
    gap: 0px;
  }

  .series-lesson-wrapper {
    margin-bottom: 20px;
  }

}


@media screen and (min-width: 700px) {

  .series-lessons-wrapper {
    gap: 30px;
  }

  .series-lesson-wrapper {
    width: 47%;
  }

}

@media screen and (min-width: 1000px) {

  .series-lesson-wrapper {
    width: 31%;
  }

}


@media screen and (min-width: 1400px) {

  .series-lesson-wrapper {
    width: 23%;
  }

}


/* ------------------------------------ */
/* Series Filters */
/* ------------------------------------ */

.library-filter-container {
  justify-content: flex-start;
  flex-wrap: wrap;
  /* margin-top: -30px; */
  margin-bottom: 45px;
}

.library-filter-container .form-field {
  width: 190px;
  margin-right: 15px;
}

.library-filter-container label {
  display: block;
}

.library-filter-container input,
.library-filter-container select {
  width: 100%;
  padding: 8px;
  border: 1px solid var(--gray-tertiary);
  border-radius: 5px;
}

.library-filter-container .form-field.clear-filters label {
  height: 22px;
}

.library-filter-container button {
  color: var(--gray-tertiary);
}



@media screen and (max-width: 800px) {

  .library-filter-container .form-field {
    width: 30%;
    margin-right: 15px;
  }

  .library-filter-container .form-field.clear-filters label {
    height: 15px;
  }

}

@media screen and (max-width: 550px) {

  .library-filter-container .form-field {
    width: 45%;
    margin-top: 15px;
  }

  .library-filter-container .form-field.search-input {
    width: 100%;
  }

  .library-filter-container .form-field.clear-filters label {
    height: 0px;
  }

}