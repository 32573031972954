
.main-body-content.courses {

}

.course-list-wrapper {
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: flex-start;
  gap: 30px;
  margin-top: 30px;
}




/* ============================== */
/* Course Group */
/* ============================== */

.course-group-container {
  margin-top: 60px;
  margin-bottom: 90px;
}

.course-group-h2 {
  font-family: 'Outfit', sans-serif;
  font-size: 1.75rem;
  font-weight: 400;
}

.course-group-h2-block {
  height: 24px;
  width: 200px;
  margin-top: -18px;
  margin-left: -15px;
  border-radius: 5px;
  background-color: var(--active);
  opacity: .15;
}




/* ============================== */
/* Course Card */
/* ============================== */

.course-card-container {
  /* width: 100%;
  max-width: 30%; */
  flex: 1;
  min-width: 300px;
  /* margin-bottom: 30px; */
}

.course-card {
  position: relative;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 30px;
  border-radius: 10px;
  background-color: var(--white-primary);
  box-shadow: rgba(0, 0, 0, .1) 0px 5px 8px;
}

.course-banner {
  position: absolute;
  z-index: 1;
  top: -15px;
  padding: 5px 5px 5px 10px;
  font-family: 'Outfit', sans-serif;
  font-weight: 400;
  font-size: .8rem;
  line-height: 1.2rem;
  color: var(--white-primary);
  border-radius: 20px;
  background-color: var(--active);
}

.course-banner.active-banner {
  padding: 5px 15px;
}

.course-banner .vector-icon {
  height: 22px;
  margin-left: 5px;
  opacity: 1;
}

.course-card h3 {
  font-family: 'Outfit', sans-serif;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.75rem;
  text-align: center;
}

.course-details {
  margin-top: 12px;
  font-size: .8rem;
  font-style: italic;
  text-align: center;
  color: var(--gray-tertiary);
}

.course-activity-details {
  justify-content: center;
  align-items: stretch;
  width: calc(100% + 30px);
  margin-left: -15px;
  margin-right: -15px;
  margin-top: 30px;
  font-family: 'Outfit', sans-serif;
  text-align: center;
}

.course-activity-score {
  justify-content: center;
  width: 48%;
  max-width: 150px;
  padding: 0 15px;
  border-right: 1px solid var(--white-tertiary);
}

.course-activity-details h4 {
  font-size: 2.25rem;
  line-height: 2.25rem;
}

.course-activity-details h5 {
  font-size: 2.25rem;
  line-height: 2.25rem;
  font-weight: 400;
}

.course-activity-date {
  justify-content: center;
  width: 48%;
  max-width: 150px;
  padding: 0 15px;
}

.course-activity-weeks {
  justify-content: center;
  width: 48%;
  max-width: 150px;
  padding: 0 15px;
}

.course-description {
  margin-top: 30px;
  text-align: center;
}

button.course-action-button {
  margin-top: 30px;
  padding: 10px 30px;
  font-family: 'Outfit', sans-serif;
  font-size: 1.125rem;
  font-weight: 400;
  color: var(--white-primary);
  border-radius: 100px;
  border: none;
  background-color: var(--gray-secondary);
}

button.course-action-button:hover {
  box-shadow: rgba(0, 0, 0, .25) 0px 5px 8px;
}

button.course-action-button.retake {
  color: var(--gray-secondary);
  border: 1px solid var(--gray-secondary);
  background-color: var(--white-primary);
}

.course-upgrade-container {
  margin-top: 30px;
  text-align: center;
}

.course-upgrade-container strong {
  font-family: 'Outfit', sans-serif;
  font-weight: 500;
  color: var(--active);
}

.course-upgrade-container a {
  color: var(--gray-secondary);
  text-decoration: underline;
}

.course-upgrade-container a:hover {
  opacity: .75;
}


.course-card-container.active-course {
  max-width: initial;
  padding-bottom: 60px;
  margin-top: 60px;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--gray-secondary);
}

.course-card-container.active-course .course-card {
  max-width: 50%;
}

.course-cancel {
  margin-top: 30px;
  color: var(--gray-primary);
  text-decoration: underline;
  opacity: .6;
}

.course-cancel:hover {
  opacity: 1;
}


.course-prep-wrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 30001;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
}

.course-prep-wrapper .course-prep {
  width: 75%;
  min-width: 275px;
  max-width: 500px;

  background-color: #fff;
  padding: 30px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, .5) 0px 0px 8px;
}

.course-prep-wrapper .loading-general {
  width: 100%;
  padding: 30px 30px;
}

.course-prep-wrapper .course-prep.ready {
  text-align: center;
  padding-top: 45px;
  padding-bottom: 45px;
}

.course-prep-wrapper .course-prep.ready a {
  display: inline-block;
  font-size: 1.125rem;
  font-weight: 500;
  color: var(--white-primary);
  margin-top: 15px;
  padding: 10px 30px;
  border-radius: 5px;
  background-color: var(--gray-primary);
}


/* .lesson-card.course {
  display: flex;
  flex-direction: column;
  background-color: var(--white-primary);
} */

/* .course-card-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
} */

/* .course-card-header h3 {
  font-size: 1.125rem;
} */

/* .course-free-label {
  color: var(--active);
} */

/* .course-card-header .premium-label {
  border-radius: 5px;
} */

/* .course-card-details {
  color: var(--gray-tertiary);
  margin-bottom: 30px;
} */

/* .course-card-details span {
  display: inline-block;
  width: 90px;
} */

/* .lesson-card.course .course-description {
  margin-bottom: 30px;
  flex-grow: 1;
} */

/* .lesson-card.course .action-wrapper.in-progress {
  display: flex;
  align-items: center;
  justify-content: space-between;
} */

/* .lesson-card.course .action-wrapper .active-label {
  font-weight: 500;
} */

/* .lesson-card.course .action-wrapper .course-cancel {
  background:initial;
  border: initial;
  font-size: .9rem;
  text-decoration: underline;
  color: var(--gray-tertiary);
  cursor: pointer;
} */

/* .lesson-card.course .course-upgrade {
  text-decoration: underline;
  color: var(--gray-active);
  font-size: .9rem;
} */

/* .lesson-card.course .action-button-wrapper {
  margin-top: initial;
} */

.course-prep-wrapper p.course-error-text {
  text-align: left;
  margin-top: 15px;
}

.course-prep-wrapper .course-close-modal {
  margin-top: 30px;
  background: initial;
  border: initial;
  color: var(--gray-primary);
  text-decoration: underline;
}

/* .course-completed-retake-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
} */

/* .course-completed-retake-wrapper p {
  color: var(--active);
  font-weight: 500;

  display: flex;
  align-items: center;
} */

/* .course-completed-retake-wrapper button {
  border: initial;
  background-color: initial;
  font-size: .9rem;
  text-decoration: underline;
  cursor: pointer;
  color: var(--gray-tertiary);
} */






/* @media screen and (min-width: 700px) {

  .course-list-wrapper {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
  }

  .lesson-card.course {
    width: calc(50% - 30px);
  }

} */

/* @media screen and (min-width: 1200px) {

  .lesson-card.course {
    width: calc(33% - 30px);
  }

} */


@media screen and (max-width: 900px) {

  .course-card-container.active-course .course-card {
    max-width: 500px;
  }
}