
.primary-content-wrapper.conversation {
  display: none;
}

.secondary-content-wrapper.conversation {
  position: relative; /* need this for the conversation complete screen */

  display: flex;
  height: 100vh;
  flex-direction: column;
  padding: 30px 0px 0px 0px;
}




/* Responsive Layout */
@media screen and (min-width: 900px) {

  .primary-content-wrapper.conversation {
    display: block;
  }

  .secondary-vertical-flex-wrapper.conversation {
    /* background: rgba(255, 255, 255, .2); */
    background-color: #fff;
  }

  .secondary-content-wrapper.conversation {
    padding-left: initial;
    padding-right: initial;
    border-right: 1px solid var(--primary-green);
  }

}






/* .conversation-wrapper {
  background-color: #fff;
  height: 300px;
  overflow-y: scroll;
} */

.record-wrapper {
  height: 18vh;
}

/* .conversation-list-wrapper {
  padding: 15px;
} */

.questions {
  position: relative; /* Need this to make score modals stick to their positions */
  /* margin-bottom: 75px; */
  /* padding: 15px; */
}

.media-left .icon-person,
.media-left .icon-info,
.response-wrapper .icon-retry {
  opacity: .5;
}

.response-wrapper .icon-retry:hover {
  opacity: .8;
}





/* .question-wrapper.introduction .card-content {
  background-color: var(--light-green);
  border-color: var(--primary-green-active);
  margin: 30px 0 10px 0;
  border-radius: 5px;
  min-width: 200px;
} */

.introduction p {
  /* margin-top: 10px; */
  /* margin-bottom: 10px; */
}

.introduction p.headline {
  font-weight: 600;
  font-style: initial;
  margin-bottom: 10px;
}

.introduction p {
  font-style: italic;
}




.card-content {
  padding: 10px 20px;
  /* max-width: 90%; */
}

.question-wrapper {
  margin-left: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.question-wrapper.introduction.community-index,
.question-wrapper.introduction.community-response {
  width: 90%;
}

.response-wrapper .flex,
.question-wrapper .flex {
  display: flex;
  align-items: center;
}

.response-wrapper .flex {
  justify-content: space-between;
}

/* .response-wrapper {
  margin-left: 15px;
  justify-content: space-between;
} */

.response-wrapper .media,
.question-wrapper .media {
  align-items: center;
}

.question-wrapper.image img {
  border-radius: 20px;
  width: auto;
  /* padding: 10px; */
}

.question-wrapper.image .card-content {
  padding: 0px;
  width: 85%;
  max-width: 500px;
}

.question-wrapper.video iframe  {
  border-radius: 20px;
  padding: 10px;
}

.response-content {
  margin-left: 45px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  min-width: 300px;
}

.response-content .card-content {
  /* width: 100%;
  min-width: 100%;
  max-width: 100%; */
}

.response-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  /* margin: 10px 15px 10px 0; */
}

.response-wrapper.inactive .card-content,
.response-wrapper.previous .card-content {
  opacity: .85;
}

.response-wrapper.active {
  padding-bottom: 30px;
}

.response-content .response-extras {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 100%;
  margin-top: 2px;
  padding-left: 10px;
  padding-right: 10px;
  /* color: var(--gray-tertiary); */
  font-size: .9rem;
}

.response-extras .try-again-wrapper {
  min-width: 10px;
}

.response-extras .try-again {
  cursor: pointer;
}

.response-wrapper.active .response-extras {
  /* display: none; */
}

.response-wrapper.active .response-extras .try-again,
.response-wrapper.previous .response-extras .try-again,
.response-wrapper.active .response-extras .response-score {
  display: none;
}

.response-wrapper.active.in-review .response-extras .try-again,
.response-wrapper.active.in-review .response-extras .response-score {
  display: block;
}

.response-wrapper .response-extras .try-again,
.response-wrapper .response-extras .see-suggestions,
.response-wrapper .response-extras .response-score {
  padding: 5px 2px;
  font-size: .9rem;
}

.response-wrapper.active.in-review .response-extras .response-score {
  display: flex;
  align-items: center;
}

.response-extras .try-again:hover {
  color: var(--gray-primary)
}

.response-extras .response-score {
  width: 80px;
  display: flex;
  justify-content: flex-end;
}

.response-extras .response-score span {
  margin-left: 5px;
  font-weight: 500;
  cursor: pointer;
}

/* .response-extras .response-score .score-donut-wrapper,
.score-details .score-detail-breakdown .score-donut-wrapper { */
.score-donut-wrapper,
.score-donut-wrapper {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  margin-left: 5px;
  pointer-events: none;
}

/* .response-extras .response-score .score-donut-wrapper .score-donut,
.score-details .score-detail-breakdown .score-donut-wrapper .score-donut { */
.score-donut-wrapper .score-donut,
.score-donut-wrapper .score-donut {
  position: absolute;
  z-index: 2;
  top: 0px;
  left: 0px;
  width: 20px;
  height: 20px;
}

/* .response-extras .response-score .score-donut-wrapper .donut-hole,
.score-details .score-detail-breakdown .score-donut-wrapper .donut-hole { */
.score-donut-wrapper .donut-hole,
.score-donut-wrapper .donut-hole {
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
  width: 20px;
  height: 20px;
  border: 4px solid var(--white-tertiary);
  border-radius: 10px;
}

/* .score-details .score-detail-breakdown .score-detail-breakdown-score {
  display: flex;
} */

.autoplay-notice {
  display: inline-block;
  margin: 10px 15px;
  padding: 10px 20px;
  border-radius: 20px;
  background-color: var(--gray-background);
}





/* Links to other modules */
a.link-to-module {
  display: inline-block;
  border: 1px solid var(--mid-gray);
  border-radius: 5px;
  padding: 5px 10px;
  color: var(--dark-gray);
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

a.link-to-module:hover {
  border-color: var(--dark-gray);
  background-color:rgba(255, 255, 255, .25);
}





/* Help button and modal */

.help-button {
  width: 50%;
  background-color: var(--light-light-gray);
  border: 1px solid var(--light-gray);
  text-align: center;
  border-radius: 30px;
  padding: 5px;
  margin: -15px auto 15px;
  cursor: pointer;
  user-select: none;
}

.help-button:hover {
  border-color: var(--dark-green);
  color: var(--dark-green);
}

.help-bubble {
  /* position: absolute; */
  position: fixed; /* -- new -- */
  /* z-index: 1000; */
  z-index: 3000; /* -- new -- */
  top: 20%; /* -- new -- */
  left: 0;
  right: 0;
  margin: auto;
  /* width: 70%; */
  width: 40%; /* -- new -- */
  max-width: calc(var(--wrapper-width) - 60px);

  background-color: #fff;
  /* box-shadow: rgba(0, 0, 0, .25) 0px 0px 8px; */
  box-shadow: rgba(0, 0, 0, .75) 0px 0px 8px; /* -- new -- */
  padding: 15px;
  border-radius: 10px;
}

.help-bubble.example-responses {
  /* margin-top: -450px; */
  /* margin-top: -425px; */
}

.help-bubble.gem {
  /* border-color: #00AFE7; */
}

.help-bubble .media {
  margin: 10px 0 10px 10px;
}

.help-bubble .title {
  /* font-size: 1.1rem; */
  font-size: 1rem;
  font-weight: 500;
}

.help-bubble .close-bubble {
  cursor: pointer;
  width: 16px;
  height: 16px;
}

.help-bubble .help-content {
  padding: 10px 0 10px 10px;
  border-bottom: 1px dotted var(--light-gray);
}

.help-bubble .help-content .label {
  font-size: .9rem;
  font-style: italic;
  font-weight: 400;
  margin-bottom: 0px;
}

.help-bubble .help-content .text {
  font-size: .9rem;
}

.help-bubble .help-content .media {
  margin: 0px;
}

@media screen and (max-height: 400px) {

  .help-bubble {
    width: 60%;
  }

}

@media screen and (max-width: 600px) {

  .help-bubble {
    width: 90%;
  }

  .icon-gem {
    padding-left: 15px;
  }

}






/* Active Question Card */
/* .response-wrapper.active .card-content {
  border-color: var(--primary-green);
} */

.response-wrapper.active .play-audio-icon {
  display: none;
}

/* .response-wrapper.active .response-input {
  color:#A5A5A5;
} */

/* Previous Question Card */
.response-wrapper.previous .response-input {
  /* color: var(--mid-dark-green); */
  color: var(--dark-green);
}


/* Inactive Question Cards */
.response-wrapper.inactive .response-input {
  /* color: var(--mid-dark-green); */
  color: var(--dark-green);
}


/* .response-wrapper.active .icon-retry,
.response-wrapper.inactive .icon-retry, */
.response-wrapper.inactive .try-again {
  display: none;
}

.response-wrapper .see-suggestions {
  cursor: pointer;
}




/* ------------------------------------------------------------ */
/* Definition Modal */
/* ------------------------------------------------------------ */

.definition-modal {
  z-index: 2000;
  padding: 15px 30px;
  border: 1px solid var(--dark-gray);
  border-radius: 15px;
  background-color: #fff;
  pointer-events: none;
}




/* ------------------------------------------------------------ */
/* Score Details Modal */
/* ------------------------------------------------------------ */

.score-details {
  position: absolute;
  z-index: 3000;
  width: 100%;
  width: 375px;
  top: 40px;
  right: 0px;
}

.score-details-inner {
  position: relative;
  margin-bottom: 45px;
  padding: 15px;
  border-radius: 10px;
  background-color: var(--white-primary);
  border: 3px solid var(--gray-tertiary);
}


@media screen and (max-width: 420px) {
  .score-details {
    width: 320px;
  }
}

@media screen and (max-width: 350px) {
  .score-details {
    width: 280px;
  }
}

.score-details .score-details-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.score-details .score-details-header .icon {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.score-details .score-detail-breakdown .score-detail-breakdown-item {
  margin-bottom: 10px;
}

.score-details .score-detail-breakdown .score-detail-breakdown-item .vector-icon {
  height: 20px;
  margin-right: 5px;
}

.score-details .score-detail-breakdown .score-detail-breakdown-item p {
  margin: 5px 45px 0 25px;
  color: var(--gray-tertiary);
  font-size: .9rem;
  line-height: 1.2rem;
}

.score-details .score-detail-breakdown .score-detail-breakdown-item .dummy-toggle {
  width: 25px;
  height: 20px;
}

.score-details .score-detail-breakdown .score-detail-breakdown-item .error {
  color: var(--error-text-alt);
  font-style: italic;
}

.score-details .score-detail-breakdown p:first-of-type {
  margin-bottom: 15px;
}

.score-details .arrow-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 12px solid var(--gray-tertiary);
  position: absolute;
  right: 23px;
  top: -12px;
}

.word-detail-breakdown {
  display: flex;
  flex-wrap: wrap;
  /* margin-top: 15px; */
  margin-left: 20px;
  margin-top: -5px;
}

.word-detail-breakdown span,
.word-detail-breakdown button {
  border: initial;
  font-family: inherit;
  font-size: initial;
  background-color: transparent;

  padding: 1px 2px;
  margin: 2px;
  cursor: help;
}

.word-detail-breakdown span.great,
.word-detail-breakdown span.Excellent,
.word-score-phone-container span.Excellent,
.word-detail-breakdown button.great,
.word-detail-breakdown button.Excellent,
.word-score-phone-container button.Excellent {
  background-color:#b0fdba;
}

.word-detail-breakdown span.good,
.word-detail-breakdown span.Good,
.word-score-phone-container span.Good,
.word-detail-breakdown button.good,
.word-detail-breakdown button.Good,
.word-score-phone-container button.Good {
  background-color:#dcfdb0;
}

.word-detail-breakdown span.ok,
.word-detail-breakdown span.Ok,
.word-score-phone-container span.Ok,
.word-detail-breakdown button.ok,
.word-detail-breakdown button.Ok,
.word-score-phone-container button.Ok {
  background-color:#ffecb9;
}

.word-detail-breakdown span.needs-improvement,
.word-score-phone-container span.needs-improvement,
.word-detail-breakdown button.needs-improvement,
.word-score-phone-container button.needs-improvement {
  background-color:#f39cc7;
}

.word-detail-breakdown .helper-text {
  width: 100%;
  text-align: center;
  margin: 10px 0 5px 0;
  font-style: italic;
  color: var(--gray-tertiary);
}


.grammar-detail-breakdown {
  margin-top: 15px;
}

.grammar-detail-breakdown .grammar-error {
  padding: 0px;
  text-decoration: underline;
  text-decoration-style: wavy;
  text-decoration-color: var(--error-text-alt);
  cursor: help;
}

.grammar-detail-breakdown hr {
  margin: 15px 0;
}


.score-details.no-access .score-detail-breakdown,
.score-details.no-access .word-detail-breakdown,
.score-details.no-access .grammar-detail-breakdown {
  filter: blur(3px);
}

.word-detail-premium-cta {
  position: absolute;
  z-index: 100;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: calc(100% - 45px);
  border-radius: 8px;
  text-align: center;
  font-size: 1.1rem;
  background-color: rgba(255, 255, 255, 0.65);
}

.word-detail-premium-cta .button {
  color: var(--white-primary);
  background-color: var(--gray-primary);
  border: none;
}

.score-detail-breakdown.obscure,
.word-detail-breakdown.obscure {
  filter: blur(2px);
  -webkit-filter: blur(2px);
}


.word-score-tooltip-wrapper {
  position: absolute;
  z-index: 90;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.1);
  pointer-events: none;
}

.word-score-tooltip {
  position: absolute;
  z-index: 100;
  left: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: calc(100% - 20px);
  padding: 15px;

  /* border: 2px solid var(--active); */
  border: 1px solid var(--gray-tertiary);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, .25) 0px 0px 8px;
  background-color: var(--white-primary);

  pointer-events: all;
}

.word-score-tooltip button {
  cursor: pointer;
}

.word-score-tooltip-left {
  flex-grow: 1;
  margin-right: 15px;
}

.word-score-tooltip-left hr {
  margin: 10px;
}

.word-score-tooltip-left p {
  color: var(--error-text);
}

.word-score-tooltip-right {
  margin-left: -28px;
}

.word-score-tooltip-right .icon {
  width: 16px;
  height: 16px;
}

.word-score-tooltip .arrow-down {
  position: absolute;
  bottom: -13px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 12px solid var(--gray-tertiary);
}




/* Word Score Phone */

.word-score-phone-container {
  width: 100%;
}

.word-score-phone-container div {
  display: flex;
  flex-direction: row;
}

.word-score-phone-container span {
  padding: 3px 10px;
  box-sizing: border-box;
  border-radius: 5px;
}

.word-score-phone-container div {
  margin-bottom: 2px;
}

.word-score-phone-container span:first-of-type {
  width: 30%;
  margin-right: 2px;
}

.word-score-phone-container span:last-of-type {
  width: 70%;
}

.word-score-phone-container .phone-container {
  padding: 0px;
}

.word-score-phone-container .phone-container .phone-dot {
  font-weight: 900;
}

.word-score-phone-container .phone-container:last-of-type .phone-dot {
  display: none;
}




/* Score Tabs */

.score-tabs-container {
  margin-top: 30px;
}

.score-tabs-container .score-tab {
  width: 100%;
  padding-bottom: 5px;
  border-bottom: 2px solid var(--white-tertiary);
  cursor: pointer;
}

.score-tabs-container .score-tab.active {
  border-color: var(--active);
}


/* Popup Modal */

.tooltip-wrapper {
  position: absolute;
  z-index: 2000;
  left: 0;
  right: 0;
  top: 200px;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  max-width: 350px;

  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, .5) 0px 0px 8px;
}

.tooltip-wrapper.intro-video {
  position: fixed;
  z-index: 4000;
  top: 43%;
  transform: translate(0%, -50%);

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 15px;
  max-width: 350px;
}

.tooltip-wrapper.intro-video video {
  width: 100%;
}

.tooltip-wrapper.intro-video .button-holder {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}

.tooltip-wrapper.intro-video .tooltip-close {
  color: var(--gray-tertiary);
  cursor: pointer;
}

.tooltip-wrapper.intro-video .tooltip-close:hover {
  color: var(--gray-primary);
}


@media screen and (min-width: 700px) and (min-height: 600px) {

  .tooltip-wrapper.intro-video {
    top: 50%;
    padding: 30px;
    width: 80%;
    max-width: 700px;
  }

}

@media screen and (max-height: 750px) {

  .tooltip-wrapper.intro-video video {
    width: 230px;
  }

}

@media screen and (min-width: 400px) and (max-height: 600px) {

  .tooltip-wrapper.intro-video {
    top: 50%;
    width: 50%;
    max-width: 450px;

  }

  .tooltip-wrapper.intro-video video {
    width: 100%;
  }

}

@media screen and (min-width: 400px) and (max-height: 600px) {

  .tooltip-wrapper.intro-video {
    height: 80%;
    max-height: 550px;
  }

  .tooltip-wrapper.intro-video video {
    /* width: 100%; */
    height: 60%;
    max-height: 400px;
  }

}



/* Loading */

.loading-general.grammar {
  padding: 15px;
}





/* Helper hint */

.response-score-wrapper {
  cursor: pointer;
}

.response-extras {
  position: relative;
}

/* Unset the position for the Bite */
.lesson-body.bite .response-extras {
  position: initial;
}

.score-helper-hint-container {
  position: absolute;
  top: 30px;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  -webkit-animation: up-arrow-fade-delay 2.5s infinite ease-in-out both;
          animation: up-arrow-fade-delay 2.5s infinite ease-in-out both;
}

.score-helper-hint-container .icon {
  width: 20px;
  height: 20px;
  margin-right: 12px;
}

@-webkit-keyframes up-arrow-fade-delay {
  0%, 50%, 100% { opacity: .5; margin-top: 2px; }
  25%, 75% { opacity: .75; margin-top: 0px; }
}

@keyframes up-arrow-fade-delay {
  0%, 50%, 100% { opacity: .5; margin-top: 2px; }
  25%, 75% { opacity: .75; margin-top: 0px; }
}

.score-helper-hint-container p {
  text-align: center;
  padding: 3px;
  margin-top: 2px;
  border: 1px solid var(--gray-tertiary);
  border-radius: 3px;
}



/* ------------------------------------------------------------ */
/* Transcript scores by word */
/* ------------------------------------------------------------ */

.transcript-words-score-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.transcript-words-score-container button {
  cursor: pointer;
  padding: 0px;
  margin: 0px 0 2px 0;
}

.transcript-words-score-container button.Excellent {
  color: #0a4629;
}

.transcript-words-score-container button.Good {
  color: #8a7f00;
  border-bottom: 1px dashed #8a7f00;
}

.transcript-words-score-container button.Ok {
  color: #992400;
  border-bottom: 1px dashed #992400;
}



/* ------------------------------------------------------------ */
/* Word score modal */
/* ------------------------------------------------------------ */


.window-modal.word-score-modal {
  padding: 0px;
}

.window-modal.word-score-modal p {
  color: var(--gray-tertiary);
}

.word-score-modal-header {
  padding: 30px 30px 10px;
  border-bottom: 1px solid var(--white-tertiary);
}

.word-score-modal-header h2 {
  text-align: center;
}

.word-score-modal-header p {
  text-transform: capitalize;
}

.word-score-modal-header button {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.word-score-modal-body {
  padding: 30px;
  width: 100%;
}

.word-score-modal-body .phone-dot {
  padding: 0 5px;
}

.word-score-modal-phone-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.word-score-modal-phone-container div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 265px;
  margin-bottom: 5px;
  border-radius: 3px;
  border: 1px solid var(--white-tertiary);
}

.word-score-modal-phone-container div span:first-of-type {
  width: 55px;
  padding-left: 15px;
  text-align: left;
  background-color: var(--gray-light);
}

.word-score-modal-phone-container div span:last-of-type {
  padding-left: 15px;
}

.word-score-modal-phone-container div.Good {
  border-color: #8a7f00;
}

.word-score-modal-phone-container div.Good span:first-of-type {
  background-color: #8a7f00;
  color: var(--white-primary);
}

.word-score-modal-phone-container div.Ok,
.word-score-modal-phone-container div.needs-improvement {
  border-color: #992400;
}

.word-score-modal-phone-container div.Ok span:first-of-type,
.word-score-modal-phone-container div.needs-improvement span:first-of-type {
  background-color: #992400;
  color: var(--white-primary);
}

.word-score-modal-body .phone-container:last-of-type .phone-dot {
  display: none;
}


.word-score-modal-footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.word-score-modal-footer button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px;
}