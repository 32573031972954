:root {
  --left-column-max-width: 435px;
  --right-column-max-width: 365px;
}


.main-body.community .header {
  display: flex;
  align-items: center;
}

.main-body.community .header .title-wrapper {
  display: flex;
  flex-direction: row;
  max-width: 800px;
  padding: 0 30px;
}

.main-body-content-flex-wrapper {
  display: flex;
  justify-content: center;
}

.main-body-content.community {
  display: flex;
  flex-direction: row;
  justify-content: top;
  width: 100%;
  max-width: 800px;
  padding: 0px;
}

.main-body-content.community .left-column {
  width: 100%;
  max-width: var(--left-column-max-width);
  padding: 0 15px;
}

.main-body-content.community .right-column {
  display: none;
  flex-grow: 1;
  max-width: var(--right-column-max-width);
}


/* Comments */

.main-body.community .header.comments .title-wrapper {
  justify-content: flex-start;
}

.main-body.community .header.comments h2 {
  margin-bottom: 5px;
  margin-left: 15px;
}

/* .main-body-content.community.comments {

} */

.main-body-content.community.comments .right-column {
  display: block;
  position: relative;
  width: 100%;
  max-width: 700px;
  min-height: 200px;
  /* padding: 0 30px; */
}


@media screen and (max-width: 700px) {

  .main-body-content.community.comments {
    margin-bottom: -120px;
  }

  .main-body.community .header .title-wrapper {
    padding: 0;
  }

  .main-body-content.community .left-column {
    width: 100%;
    max-width: 700px;
  }

  .main-body-content.community.comments {
    flex-direction: column;
  }

  .main-body-content.comments .blank-state-general {
    padding: 30px 60px;
  }

}


@media screen and (min-width: 700px) {

  .main-body.community .header .title-wrapper {
    width: 100%;
  }

  .main-body-content.community .right-column {
    display: block;
  }

  .main-body-content.community .left-column {
    padding: 0 30px;
  }

  .main-body-content.community .left-column .dynamic-elements {
    display: none;
  }

  .main-body-content.community .loading-general {
    width: 375px;
  }

}




/* -------------------------------- */
/* Right Column */
/* -------------------------------- */

.main-body-content.community .right-column ul {
  list-style: disc;
  margin-top: 10px;
  margin-left: 30px;
}


.main-body-content.community .right-column .new-post-wrapper {
  margin-top: -26px;
  margin-bottom: 30px;
  border-bottom: none;
}




/* -------------------------------- */
/* New Post */
/* -------------------------------- */

.new-post-wrapper {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--white-tertiary);
}

.new-post-form {

}

.new-post-form .field.input-select {
  height: initial;
  transition: opacity 100ms ease-in;
  opacity: 1;
}

.new-post-form .select {
  width: 100%;
}

/* .new-post-form .new-post-main {

} */

.new-post-form .control .textarea,
#text-editor-basic {
  padding: 5px !important;
  min-height: 120px !important;
  border: 1px solid var(--white-tertiary) !important;
  border-radius: 5px !important;
  overflow-y: scroll;
  background-color: var(--white-primary);

  transition: 100ms linear all;
}

.new-post-form .emoji-button-wrapper {
  display: none;
}


.new-post-form .new-post-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.new-post-form .new-post-record-audio {
  /* width: 10px;
  height: 10px; */
}

.new-post-form .submit-button .button {
  background-color: var(--active);
  color: var(--white-primary);
  border: initial;
  height: 40px;
}


/* ----------------- */
/* Form inactive state */
/* ----------------- */

.new-post-form.inactive {

}

.new-post-form.inactive .field {
  margin: 0px;
}

.new-post-form.inactive .new-post-main {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.new-post-form.inactive .field:first-of-type {
  flex-grow: 1;
}

.new-post-form.inactive .control .textarea,
.new-post-form.inactive .control #text-editor-basic {
  max-height: 40px !important;
  min-height: 40px !important;
  padding: 7px;
}

/* .new-post-form.inactive .select { */
.new-post-form.inactive .field.input-select {
  /* display: none; */
  opacity: 0;
  height: 0px;
}

.new-post-form.inactive .new-post-record-audio {
  display: none;
}

.new-post-form.inactive .submit-button {
  margin-left: 10px;
}






/* -------------------------------- */
/* Individual Post */
/* -------------------------------- */


.post-wrapper {
  margin-bottom: 30px;
  background-color: var(--white-primary);
  border-radius: 5px;
  box-shadow: 0 6px 15px -4px rgb(0,0,0, .3);
}

.post-wrapper a {
  color: var(--gray-primary);
}



/* ------------ */
/* Header       */
/* ------------ */

/* .post-header,
.post-footer {
  font-size: .8rem;
}

.post-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3px;
} */

.post-header {
  position: relative;
  display: flex;
  /* align-items: flex-end; */
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  border-bottom: 1px solid var(--white-tertiary);
}

.post-header .header-left {
  display: flex;
  align-items: center;
}

.post-header .header-image {
  width: 36px;
  height: 36px;
  margin-right: 10px;
  background: rgb(153,20,131);
}

.post-header .header-name-wrapper {

}

.post-header .header-name-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: .9rem;
  font-weight: 500;
}

.post-header .header-name-info span:first-of-type {
  margin-right: 10px;
}

.post-header .header-name-info img {
  height: 15px;
  width: 25px;
}

.post-header .header-flow-score {
  font-size: .8rem;
  color: var(--gray-tertiary);
}

.post-header .header-right {
  font-size: .8rem;
  color: var(--gray-tertiary);
}

.post-header {
  overflow: hidden;
}

.post-header .category-flag {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 30px 30px 0;
  border-color: transparent transparent transparent transparent;
  opacity: .5;
}

.post-header .category-flag.feedback-request {
  border-color: transparent #4A1669 transparent transparent;
}

.post-header .category-flag.general {
  border-color: transparent #23A3E4 transparent transparent;
}

.post-header .category-flag.explain-this-to-me {
  border-color: transparent #A8306E transparent transparent;
}

.post-header .category-flag.pronunciation-help {
  border-color: transparent #5FB3B3 transparent transparent;
}

.post-header .category-flag.funny-english {
  border-color: transparent #B68447 transparent transparent;
}





/* ------------ */
/* Main Image   */
/* ------------ */

.post-main-image {
  position: relative;
}

.post-main-image .main-image {
  width: 100%;
  height: 275px;
}

.post-main-image .header-text-audio-wrapper {
  position: absolute;
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 60%;
  bottom: 0px;
  background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.8) 100%);
}

.post-main-image .header-text-audio {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 15px 15px;
}

.post-main-image .header-text {
  font-size: 2rem;
  font-weight: 500;
  line-height: 2rem;
  color: var(--white-primary);
  margin-right: 30px;
}

.post-main-image .header-text.small {
  font-size: 1.5rem;
  line-height: 1.8rem;
}

.post-main-image .header-text.smaller {
  font-size: 1.25rem;
  line-height: 1.5rem;
}

.post-main-image .header-text .sub-heading {
  font-weight: 400;
  font-size: 1rem;
}

.post-main-image .header-audio {
  width: 75px;
  min-width: 75px;
  height: 75px;
  background-image: url('../images/icons/icon-audio-big-light.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}

@media screen and (min-width: 700px) {

  .post-main-image .main-image {
    height: 300px;
  }

}




/* ------------ */
/* Content      */
/* ------------ */

a.post-content,
a.post-comments {
  display: block;
}

a.post-comments:hover {
  text-decoration: underline;
}

.post-category {
  /* padding: 10px 15px 0 15px; */
  margin-bottom: 10px;
  font-size: .8rem;
  font-style: italic;
}

/* .post-body-wrapper {
  padding-right: 15px;
} */

.post-body,
.post-question {
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.post-body {
  padding: 10px 15px;
  min-height: 100px;
}

/* Handle funky elements */
.post-body div,
.post-body span,
.post-body a {
  max-width: 345px;
  word-wrap: break-word;
}

.post-body p {
  margin-bottom: 15px;
}

.post-body .body-heading {
  font-size: .9rem;
  font-style: italic;
  padding: 0 15px;
}

.post-body .post-body-audio {
  display: flex;
  align-items: center;
  margin-left: 15px;
}

/* .post-body-text {
  width: 100%;
  padding: 5px 0 15px 15px;
} */

.post-body-text a {
  text-decoration: underline;
}

.post-body-text ol {
  padding-left: 30px;
}

.post-question {
  margin-bottom: 15px;
}

.post-content .post-highlight {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 7px;

  background-position: center;
  background-size: cover;
  background-image: url('../images/post-header-backgrounds/blue.jpg');
}

.post-content .post-highlight.explain-this-to-me {
  background-image: url('../images/post-header-backgrounds/red.jpg');
}

.post-content .post-highlight.pronunciation-help {
  background-image: url('../images/post-header-backgrounds/green.jpg');
}

.post-content .post-highlight.funny-english {
  background-image: url('../images/post-header-backgrounds/yellow.jpg');
}

.post-content .post-highlight.response-type {
  background-image: url('../images/post-header-backgrounds/purple.jpg');
}


a.question-lesson-link {
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.left-column .index-intro-card .post-body h5 {
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 10px;
}




/* ------------ */
/* Footer       */
/* ------------ */

.post-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  color: var(--gray-tertiary);
  font-size: .9rem;
}

.post-footer button.like-button,
.post-footer a {
  color: var(--gray-tertiary);
  font-size: .8rem;
  padding: 0px;
}




/* ----------------- */
/* Like Button */
/* ----------------- */

button.like-button {
  background-color: initial;
  border: initial;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: var(--gray-primary);
}

button.like-button div {
  pointer-events: none;
}

button.like-button .icon.icon-like {
  /* default icon */
}

button.like-button.learner-like .icon.icon-like {
  background-image: url('../images/icons/icon-fire-active.png');
}

button.like-button .icon.icon-like:active {
  opacity: .5;
}

.like-count-wrapper {
  display: flex;
  align-items: center;
}

.like-count-wrapper div {
  margin-left: 5px;
}

#text-editor-basic:empty:before {
  content: attr(data-placeholder);
  color: var(--gray-tertiary);
}




/* -------------------------------- */
/* View Post Page & Comments */
/* -------------------------------- */

.main-body-content.view-post,
.main-body-content.view-response {
  margin-bottom: 110px;
}

.main-body-content.view-post .view-header {
  position: fixed;
  z-index: 3000;
  width: 100%;
  background-color: var(--white-primary);

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
}


.view-header a,
.view-header .icon {
  height: 24px;
}

.view-header .title {
  margin-bottom: 0px;
  font-size: 1.5rem;
}

.main-body-content.view-post .post-wrapper {
  padding-top: 90px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid var(--white-tertiary);
}

.main-body-content.community .comments-wrapper {
  /* height: 100%; */
  padding: 0 30px;
  overflow-y: scroll;
  /* margin-bottom: 250px; */
}

.comment-wrapper {
  padding-bottom: 30px;
}

.comment-header {
  color: var(--gray-tertiary);
  font-size: .8rem;
  font-style: italic;
}

.comment-body-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.comment-body-wrapper .comment-audio {
  margin-left: 15px;
}



/* -------------------------------- */
/* New Comment Form */
/* -------------------------------- */

.new-comment-wrapper {
  /* position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%; */
  padding: 15px;
  background-color: var(--white-primary);
  border-top: 1px solid var(--white-tertiary);
}

.new-comment-form .control div,
.new-post-main .control div {
  padding: initial !important;
  outline: initial !important;
  display: block !important;
  flex: initial !important;
  border: initial !important;
  min-height: initial !important;
  border-radius: initial !important;
}

.new-comment-wrapper #text-editor-basic.textarea {
  min-height: 90px;
  height: 90px;
  padding: 10px !important;
  border: 1px solid var(--white-tertiary) !important;
  border-radius: 5px !important;
  overflow-y: scroll;
}

/* .new-comment-wrapper .new-comment-form {

} */

.emoji-button-wrapper {
  height: 40px;
}

.emoji-button-wrapper button {
  height: 100%;
  width: 50px;
  font-size: 20px;
  background-color: transparent;
  border: initial;
}

.new-comment-wrapper .new-comment-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.new-comment-wrapper .post-record-wrapper {
  margin-left: 10px;
}

.new-comment-wrapper .post-record-wrapper .listening-spinner {
  background-color: var(--gray-primary);
}

.new-comment-wrapper .field.submit-button {

}

.new-comment-wrapper .field.submit-button .button {
  font-weight: normal;
  color: var(--white-primary);
  border: none;
  background-color: var(--gray-primary);
  height: 40px;
}


.response-feedback-container {
  margin-bottom: 15px;
}

.feedback-button-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.feedback-button-wrapper button {
  margin: 5px 5px 5px 0;
  border-radius: 100px;
  color: var(--gray-tertiary);
}


@media screen and (min-width: 700px) {

  .main-body {
    padding-bottom: 0px;
  }

  .main-body-content.community.comments .right-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: calc(100vh - 315px);
    max-width: var(--right-column-max-width);
    padding: initial;
  }

  .main-body-content.community .comments-wrapper {
    margin-bottom: 0px;
    padding: 0px;
  }

  .new-comment-wrapper {
    position: initial;
    background-color: var(--gray-background);
    padding-left: 0px;
    padding-right: 0px;
  }

}


@media screen and (max-height: 800px) {

  .main-body-content.community.comments .right-column {
    max-height: calc(100vh - 115px);
  }

}

@media screen and (max-height: 600px) {
  .main-body-content.community.comments .right-column {
    max-height: initial;
  }
}