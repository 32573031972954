.price-comparision-table-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 500px;
}

.price-comparision-table-wrapper h2 {
  margin-bottom: 15px;
  font-size: 1.7rem;
}

.price-comparision-table-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.price-comparision-table-container .feature-row {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  width: 100%;
}

.price-comparision-table-container .feature-row.head {
  font-size: 1.1rem;
  font-weight: 500;
  color: #39048C;
}

.price-comparision-table-container .feature-row.head p:first-of-type {
  padding-top: 30px;
}

.price-comparision-table-container .feature-row.head:first-of-type p {
  padding-top: 5px;
}

.price-comparision-table-container .feature-row.head div p {
  font-size: 1rem;
  font-weight: initial;
}

.price-comparision-table-container .feature-row p {
  flex: 1;
  text-align: left;
}

.price-comparision-table-container .feature-row div {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px 0;
}

.price-comparision-table-container .feature-row div:first-of-type {
  width: 95px;
  margin-right: 3px;
  background-color: #F5F5F5;
}

.price-comparision-table-container .feature-row div:last-of-type {
  width: 85px;
  background-color: #F1E8FF;
}

.price-comparision-table-container .feature-row .icon {
  width: 18px;
  height: 18px;
}

.price-comparision-table-container .feature-row:last-of-type {
  height: 15px;
}

.price-comparision-table-container .feature-row:first-of-type div {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.price-comparision-table-container .feature-row:last-of-type div {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}



@media screen and (max-width: 400px) {

  .price-comparision-table-container .feature-row p,
  .price-comparision-table-container .feature-row.head div p {
    font-size: .9rem;
  }

  .price-comparision-table-container .feature-row div {
    width: 80px;
  }

}


@media screen and (min-height: 750px) and (min-width: 545px) {

  .price-comparision-table-wrapper {
    margin-top: 30px;
  }

}